.chip-container {
  display: inline-flex;
  align-items: center;
  background-color: #e0e0e0;
  border-radius: 16px;
  padding: 0 8px;
  font-size: 14px;
  margin: 4px;
}

.chip-label {
  margin-right: 8px;
  color: #555;
}

.chip-delete {
  cursor: pointer;
  font-size: 16px;
  color: #888;
}

.chip-delete:hover {
  color: #555;
}
