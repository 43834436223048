@charset "UTF-8";
/* VARIAVEIS 

// 1.0 - font-family and font-size
// 2.0 - Colors
// 3.0 - borders
// 4.0 -
// 5.0 - 

*/
* {
  margin: 0px;
  padding: 0px; }

body {
  font-family: "Inter"; }

ul {
  list-style: none; }

a {
  text-decoration: none; }

.img-responsive {
  width: 100%; }

@media print {
  .page-container-border {
    border: none !important; }
  .navbar-nv1, .navbar-nv2, footer, .no-print, .chat-popup-button {
    display: none !important;
    opacity: 0 !important;
    z-index: 0 !important; } }

.grid-form-rows-1 {
  grid-template-rows: repeat(1, 75px); }

.grid-form-rows-2 {
  grid-template-rows: repeat(2, 75px); }

.grid-form-rows-3 {
  grid-template-rows: repeat(3, 75px); }

.grid-form-rows-4 {
  grid-template-rows: repeat(4, 75px); }

.grid-form-1 {
  display: grid;
  grid-gap: 1rem 2rem;
  grid-template-columns: repeat(1, 1fr); }

.grid-form-2 {
  display: grid;
  grid-gap: 1rem 2rem;
  grid-template-columns: repeat(2, 1fr); }

.grid-form-3 {
  display: grid;
  grid-gap: 1rem 2rem;
  grid-template-columns: repeat(3, 1fr); }

.grid-form-4 {
  display: grid;
  grid-gap: 1rem 2rem;
  grid-template-columns: repeat(4, 1fr); }

.grid-auto-flow-column {
  grid-auto-flow: column; }

@media (max-width: 1024px) {
  .grid-form-4,
  .grid-form-3 {
    display: grid;
    grid-gap: 1rem 2rem;
    grid-template-columns: repeat(2, 1fr); } }

@media (max-width: 768px) {
  .grid-form-4,
  .grid-form-2,
  .grid-form-3 {
    display: grid;
    grid-gap: 1rem 2rem;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem; } }

.red {
  color: #DB6969; }

.pink-light {
  color: #F0D9D2; }

.warning-dark {
  color: #DD7C60; }

.warning {
  color: #E59E00; }

.warning-light {
  color: #FDC1A1; }

.btn-link {
  background: none;
  border: none;
  color: #004C99; }
  .btn-link:hover {
    color: #033F7B; }

.btn-outline {
  background: transparent;
  border: none;
  color: #888585;
  font-size: 13px;
  border: 1px solid #888585;
  padding: 5px 10px;
  border-radius: 6px; }

.btn-outline:disabled {
  border-color: #88858545;
  color: #88858545; }

.navbar-button--close {
  width: 100%;
  position: relative; }
  .navbar-button--close:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f107";
    right: 20px;
    position: absolute; }

.navbar-button--open {
  width: 100%;
  position: relative; }
  .navbar-button--open:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f106";
    right: 20px;
    position: absolute; }

.label-min-width {
  min-width: 100px; }

.input-min-width {
  min-width: 300px; }

.input-select--has-error__control {
  border-color: #DB6969 !important; }

.with-error {
  border-color: #DB6969 !important;
  border-width: 2px !important; }

.helper--has-error {
  color: #DB6969; }

.input-select-v2 .css-kj6f9i-menu {
  z-index: 20; }

input:disabled {
  opacity: 0.5 !important;
  background: inherit !important; }

.disabled {
  opacity: 0.5 !important; }

.column-right {
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.table-v2-relatorios .rt-thead {
  border-bottom-width: 2px !important; }

.table-v2-relatorios .-headerGroups .rt-th {
  height: 60px;
  display: flex;
  align-items: center;
  border-right-width: 2px;
  border-right-color: #FFFFFF;
  color: #004C99;
  font-weight: 600; }

.table-v2-relatorios .-header .rt-th {
  height: 100%;
  word-wrap: break-word;
  white-space: pre-wrap;
  text-align: left;
  display: flex;
  align-items: center; }

.table-v2-relatorios .rt-tbody .rt-tr-group .rt-td {
  border-right-color: #DEDEDE;
  word-wrap: break-word;
  white-space: pre-wrap; }

.columns-adjusment .rt-tbody .rt-td {
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.columns-adjusment .table-competencia .rt-resizable-header-content {
  border: 1px solid #888585;
  padding: 3px;
  border-radius: 3px;
  background: #FFFFFF;
  color: #888585 !important; }

.column-left {
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
  width: 100%; }

.alerta-box {
  font-size: 12px;
  background: #DD7C60;
  color: #FFFFFF;
  border-radius: 4px;
  max-width: 60px;
  padding: 3px; }

.o-header {
  grid-area: header; }

.o-content {
  grid-area: content; }

.o-footer {
  grid-area: footer; }

.history-question {
  background: #E6EED5;
  color: #4A4747;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  display: inline-block; }
  .history-question:after {
    left: 99%;
    top: 25%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    pointer-events: none;
    border-left-color: #E6EED5;
    border-width: 2px;
    margin-top: -2px; }
  .history-question:before {
    left: 99%;
    top: 25%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    pointer-events: none;
    border-left-color: #E6EED5;
    border-width: 5px;
    margin-top: -5px; }

.history-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end; }

.history-answer {
  background: white;
  color: #4A4747;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  display: inline-block; }
  .history-answer:after {
    right: 99%;
    top: 25%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    pointer-events: none;
    border-right-color: white;
    border-width: 2px;
    margin-top: -2px; }
  .history-answer:before {
    right: 99%;
    top: 25%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    pointer-events: none;
    border-right-color: white;
    border-width: 5px;
    margin-top: -5px; }

.history-subtitle {
  font-size: 12px !important;
  color: #4A4747;
  margin-bottom: 2px; }

.history-group {
  font-size: 10px !important;
  color: #4A4747;
  margin-bottom: 2px; }

footer {
  background: #033F7B;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 100px;
  text-align: center; }
  footer i, footer span, footer a {
    color: #8AC1E7; }

.breadcrumbs-bg {
  background: #f2f2f2; }
  .breadcrumbs-bg .text-breadcrumb {
    color: #7D7E7E;
    font-size: 14px; }
    .breadcrumbs-bg .text-breadcrumb .link a {
      color: #004C99;
      font-weight: 600; }
  .breadcrumbs-bg .right {
    text-align: right; }

@media (max-width: 764px) {
  .text-breadcrumb {
    text-align: center !important; }
  .link a {
    color: #004C99;
    font-weight: 900; } }

.navbar-nv1 {
  padding-top: 15px;
  padding-bottom: 10px;
  background: #f2f2f2; }
  .navbar-nv1 figure {
    margin: 0px; }
  .navbar-nv1 .logo-topo img {
    max-width: 160px;
    min-width: 130px; }
  .navbar-nv1 .info-topo-logo-title {
    display: inline-flex; }
    .navbar-nv1 .info-topo-logo-title .info-modulo {
      margin-left: 20px;
      margin-top: 0px; }
      .navbar-nv1 .info-topo-logo-title .info-modulo .title-exame {
        font-weight: 700;
        color: #004C99;
        border-right: 2px solid #ccc;
        padding-right: 10px; }
      .navbar-nv1 .info-topo-logo-title .info-modulo .title-modulo {
        font-weight: 600;
        color: #9FA1A2;
        padding-left: 10px; }
  .navbar-nv1 .info-header {
    padding-top: 4px;
    display: inline-flex;
    float: right; }
    .navbar-nv1 .info-header .notifications-header .btn-notificacao {
      background: none;
      margin-right: 20px;
      padding: 0px; }
      .navbar-nv1 .info-header .notifications-header .btn-notificacao i {
        font-size: 24px;
        color: #888585;
        opacity: 1;
        transition: all .3s linear; }
      .navbar-nv1 .info-header .notifications-header .btn-notificacao .badge {
        position: relative;
        top: -15px;
        width: 20px;
        height: 20px;
        background: #F44439;
        color: white;
        line-height: 13px;
        border-radius: 20px; }
      .navbar-nv1 .info-header .notifications-header .btn-notificacao:focus {
        outline: none !important;
        box-shadow: none !important; }
        .navbar-nv1 .info-header .notifications-header .btn-notificacao:focus i {
          color: #004C99;
          transition: all .2s linear;
          transform: rotate(20deg); }
    .navbar-nv1 .info-header .notifications-header .notificacoes-popover {
      width: 400px;
      position: absolute;
      background: #fff;
      z-index: 999999;
      top: 50px;
      transform: translateX(-360px);
      border: 1px solid #f2f2f2;
      border-radius: 6px;
      box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1); }
      .navbar-nv1 .info-header .notifications-header .notificacoes-popover .header-popover {
        padding-left: 20px;
        text-align: left;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 15px;
        text-transform: uppercase;
        font-weight: 700;
        position: fixed;
        width: 100%;
        background: #f2f2f2;
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .navbar-nv1 .info-header .notifications-header .notificacoes-popover .header-popover p {
          margin: 0px; }
        .navbar-nv1 .info-header .notifications-header .notificacoes-popover .header-popover a .btn-popover {
          font-size: 13px;
          padding-left: 20px !important;
          padding-right: 20px !important;
          padding-top: 10px !important;
          padding-bottom: 10px !important;
          border-radius: 6px;
          color: white;
          font-weight: 600;
          background: #00BCD4 !important;
          margin-right: 20px !important;
          border: none;
          transition: all .3s linear; }
          .navbar-nv1 .info-header .notifications-header .notificacoes-popover .header-popover a .btn-popover:hover {
            cursor: pointer;
            background: white !important;
            transition: all .3s linear;
            color: #033F7B; }
      .navbar-nv1 .info-header .notifications-header .notificacoes-popover ul {
        max-height: 300px;
        overflow: auto;
        margin-top: 70px; }
        .navbar-nv1 .info-header .notifications-header .notificacoes-popover ul li {
          padding: 20px;
          text-align: justify;
          color: #004C99;
          font-weight: 600;
          padding-bottom: 10px;
          padding-top: 10px;
          border-bottom: 1px solid #f2f2f2;
          font-size: 15px; }
          .navbar-nv1 .info-header .notifications-header .notificacoes-popover ul li span {
            color: #888585;
            font-weight: 400;
            font-size: 13px; }
          .navbar-nv1 .info-header .notifications-header .notificacoes-popover ul li:hover {
            background: #f2f2f2;
            cursor: pointer; }
      .navbar-nv1 .info-header .notifications-header .notificacoes-popover.hidden {
        opacity: 0;
        transition: all .2s linear;
        position: absolute;
        top: 100px;
        overflow: hidden;
        z-index: -1; }
      .navbar-nv1 .info-header .notifications-header .notificacoes-popover.show {
        margin-top: 0px;
        opacity: 10;
        transition: all .2s linear;
        top: 50px;
        max-height: 500px;
        z-index: 999; }
    .navbar-nv1 .info-header .label-user {
      font-size: 13px;
      color: #033F7B;
      text-transform: capitalize;
      margin-top: .3rem !important;
      font-weight: 600;
      display: inline-flex; }
    .navbar-nv1 .info-header .btn-logout {
      background: transparent;
      border: none;
      color: #888585;
      font-size: 13px;
      border: 1px solid #888585;
      width: 50px;
      padding: 5px 0px;
      border-radius: 6px;
      margin-left: 10px; }
      .navbar-nv1 .info-header .btn-logout:hover {
        cursor: pointer;
        background: #033F7B;
        transition: all .3s ease;
        color: #fff;
        border-color: white; }
    .navbar-nv1 .info-header .profile-header .btn-profile {
      background: transparent;
      border: none;
      display: flex;
      display: inline-flex;
      align-items: center;
      transition: all .2s linear; }
      .navbar-nv1 .info-header .profile-header .btn-profile i {
        font-size: 26px;
        color: #888585; }
      .navbar-nv1 .info-header .profile-header .btn-profile figure {
        margin: 0px; }
      .navbar-nv1 .info-header .profile-header .btn-profile:focus {
        transition: all .2s linear;
        outline: none;
        border: none; }
        .navbar-nv1 .info-header .profile-header .btn-profile:focus i {
          color: #004C99;
          transition: all .2s linear; }
      .navbar-nv1 .info-header .profile-header .btn-profile:hover {
        cursor: pointer; }
    .navbar-nv1 .info-header .profile-header .profile-popover {
      position: absolute;
      min-width: 250px;
      top: 50px;
      transform: translateX(-200px);
      background: #fff;
      border-radius: 6px;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
      z-index: 999; }
      .navbar-nv1 .info-header .profile-header .profile-popover ul {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%; }
        .navbar-nv1 .info-header .profile-header .profile-popover ul a, .navbar-nv1 .info-header .profile-header .profile-popover ul .btn-visualizar-como, .navbar-nv1 .info-header .profile-header .profile-popover ul .btn-logout {
          font-size: 14px;
          color: #888585;
          font-weight: 400;
          width: 100%;
          margin: 0px;
          text-align: left;
          border: none;
          background: transparent; }
          .navbar-nv1 .info-header .profile-header .profile-popover ul a:hover, .navbar-nv1 .info-header .profile-header .profile-popover ul .btn-visualizar-como:hover, .navbar-nv1 .info-header .profile-header .profile-popover ul .btn-logout:hover {
            cursor: pointer; }
          .navbar-nv1 .info-header .profile-header .profile-popover ul a li, .navbar-nv1 .info-header .profile-header .profile-popover ul .btn-visualizar-como li, .navbar-nv1 .info-header .profile-header .profile-popover ul .btn-logout li {
            padding-left: 1rem;
            height: 50px;
            width: 100%;
            padding-top: 13px; }
            .navbar-nv1 .info-header .profile-header .profile-popover ul a li:hover, .navbar-nv1 .info-header .profile-header .profile-popover ul .btn-visualizar-como li:hover, .navbar-nv1 .info-header .profile-header .profile-popover ul .btn-logout li:hover {
              background: #D1E8F4;
              text-decoration: none;
              font-weight: 500; }
          .navbar-nv1 .info-header .profile-header .profile-popover ul a:hover, .navbar-nv1 .info-header .profile-header .profile-popover ul .btn-visualizar-como:hover, .navbar-nv1 .info-header .profile-header .profile-popover ul .btn-logout:hover {
            text-decoration: none; }
        .navbar-nv1 .info-header .profile-header .profile-popover ul .btn-visualizar-como.hidden {
          display: none; }
        .navbar-nv1 .info-header .profile-header .profile-popover ul .btn-visualizar-como.show {
          display: block; }
        .navbar-nv1 .info-header .profile-header .profile-popover ul .acoes-visualizar-como.hidden {
          display: none;
          margin-bottom: 10px; }
        .navbar-nv1 .info-header .profile-header .profile-popover ul .acoes-visualizar-como.show {
          display: flex; }
          .navbar-nv1 .info-header .profile-header .profile-popover ul .acoes-visualizar-como.show .label-back {
            font-size: 14px; }
          .navbar-nv1 .info-header .profile-header .profile-popover ul .acoes-visualizar-como.show .btn-back {
            margin-bottom: 10px;
            width: 100%;
            border-radius: 0px !important;
            text-align: left;
            background: #f2f2f2;
            color: #888585; }
          .navbar-nv1 .info-header .profile-header .profile-popover ul .acoes-visualizar-como.show .visualizar-como-label {
            width: 100%;
            font-size: 13px;
            margin-left: 16px;
            color: #888585; }
          .navbar-nv1 .info-header .profile-header .profile-popover ul .acoes-visualizar-como.show .visualizar-como-input {
            margin-top: 7px;
            margin-left: 16px;
            border-top: none;
            border-left: none;
            border-right: none;
            border-radius: 0px;
            font-size: 12px;
            padding-left: 0px;
            color: #888585;
            max-width: 150px; }
            .navbar-nv1 .info-header .profile-header .profile-popover ul .acoes-visualizar-como.show .visualizar-como-input:focus {
              outline: none !important;
              box-shadow: none; }
          .navbar-nv1 .info-header .profile-header .profile-popover ul .acoes-visualizar-como.show .visualizar-como-buscar {
            background: #033F7B !important; }
        .navbar-nv1 .info-header .profile-header .profile-popover ul .lista-visualizar-como {
          margin-left: 0px;
          margin-right: 0px;
          margin-top: 20px;
          padding-bottom: 10px;
          margin-bottom: 0px;
          padding-left: 16px;
          padding-right: 16px;
          max-height: 200px;
          overflow: auto;
          box-shadow: inset 0 0 25px #e2e2e2; }
          .navbar-nv1 .info-header .profile-header .profile-popover ul .lista-visualizar-como li {
            border-bottom: 1px solid #ccc; }
            .navbar-nv1 .info-header .profile-header .profile-popover ul .lista-visualizar-como li a {
              justify-content: space-around;
              line-height: 40px;
              font-size: 11px;
              height: 40px;
              color: #000;
              transition: all .3s ease; }
              .navbar-nv1 .info-header .profile-header .profile-popover ul .lista-visualizar-como li a:hover {
                font-weight: 600;
                color: #004C99 !important;
                cursor: pointer;
                transition: all .3s ease; }
      .navbar-nv1 .info-header .profile-header .profile-popover.hidden {
        opacity: 0;
        transition: all .2s ease-in-out;
        position: absolute;
        top: 80px;
        z-index: -1;
        overflow: auto; }
      .navbar-nv1 .info-header .profile-header .profile-popover.show {
        margin-top: 0px;
        opacity: 10;
        transition: all .2s ease-in-out;
        top: 50px;
        max-height: 500px;
        z-index: 999; }

.navbar-nv2 {
  background: #f8f8f8; }
  .navbar-nv2 .navbar-supervisor {
    display: flex;
    margin: 0px;
    flex-wrap: wrap; }
    .navbar-nv2 .navbar-supervisor a .navbar-item {
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      width: 135px;
      height: 100%;
      font-size: 12px;
      border-right: 1px solid rgba(248, 248, 248, 0.11);
      color: #004C99;
      padding: 15px 28px;
      letter-spacing: 1.5px;
      text-transform: uppercase; }
    .navbar-nv2 .navbar-supervisor a:hover {
      text-decoration: none; }
    .navbar-nv2 .navbar-supervisor .menu-dropdown {
      border: none;
      border-right: 1px solid rgba(248, 248, 248, 0.11) !important;
      color: #004C99;
      padding: 15px 28px;
      width: 135px;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      background: #f8f8f8;
      position: relative;
      font-size: 12px;
      height: 100%; }
      .navbar-nv2 .navbar-supervisor .menu-dropdown:focus {
        outline: none !important;
        border: none; }

.dropdown-desktop.hidden {
  display: none !important; }

.dropdown-desktop.show {
  display: block !important;
  position: absolute;
  top: 65px;
  left: 0;
  background-color: #f8f8f8;
  margin-top: 5px;
  max-width: 380px;
  min-width: 340px;
  transform: translateX(0px);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  padding: 16px;
  font-family: 'Inter';
  font-size: 16px;
  line-height: 14.52px;
  text-align: left; }
  .dropdown-desktop.show a li {
    text-align: left;
    border-radius: 8px;
    color: #262626;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0px;
    padding: 15px 15px 15px 26px; }
    .dropdown-desktop.show a li:hover {
      background: #C0C0C0; }
  .dropdown-desktop.show a:hover {
    text-decoration: none; }
  .dropdown-desktop.show a:focus {
    border: none !important; }

.btn-nav-mobile {
  display: none; }

.navbar-nv2-mobile.hidden {
  max-height: 0;
  transition: max-height .1s ease-out;
  overflow: hidden; }

.navbar-nv2-mobile.show {
  transition: max-height .2s ease-in;
  overflow: hidden; }

.btn-navbar {
  margin-left: 20px;
  margin-top: -10px;
  width: 35px;
  height: 35px;
  border-radius: 30px;
  transition: all .3s ease;
  border: 1px solid #888585; }
  .btn-navbar:focus {
    outline: none;
    background: #C0C0C0;
    border: 1px solid #8AC1E7;
    box-shadow: 0 0 15px #ccc; }
  .btn-navbar:hover {
    cursor: pointer;
    background: #262626;
    transition: all .3s ease;
    border: 1px solid #8AC1E7; }

.navbar-nv2-mobile {
  background: #f8f8f8; }
  .navbar-nv2-mobile .btn-dropdown {
    background: #f8f8f8;
    color: #262626 !important;
    margin-left: 1rem;
    font-weight: 400 !important; }
  .navbar-nv2-mobile .subDropDownShow {
    margin-left: 1.5rem !important;
    background: #f8f8f8;
    color: #262626 !important; }
  .navbar-nv2-mobile a .navbar-item {
    text-align: left;
    color: #262626;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 26px;
    padding-right: 16px;
    border-radius: 0px; }
  .navbar-nv2-mobile ul {
    margin: 0px; }
    .navbar-nv2-mobile ul a li {
      color: #262626;
      padding: 15px 30px;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      text-align: left; }
    .navbar-nv2-mobile ul a:hover {
      text-decoration: none; }
      .navbar-nv2-mobile ul a:hover li {
        background: #f8f8f8; }
  .navbar-nv2-mobile .drop-down-mobile a li {
    font-size: 14px;
    color: #f8f8f8;
    text-align: left;
    padding-left: 55px; }
  .navbar-nv2-mobile .drop-down-mobile .parent-dropdown {
    color: #a8deea;
    padding: 15px 30px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    text-align: left;
    font-size: 14px; }

@media (max-width: 790px) {
  .hidden-xs {
    display: none !important; }
  .btn-nav-mobile {
    display: block !important; }
  .navbar-nv1 {
    padding-top: 10px;
    padding-bottom: 10px; }
    .navbar-nv1 .logo-topo {
      padding-top: 10px; }
    .navbar-nv1 .info-modulo {
      display: none; }
  .notifications-header {
    padding-top: 10px; }
    .notifications-header .btn-notificacao {
      margin-right: 0px !important; }
    .notifications-header .notificacoes-popover {
      position: absolute;
      transform: translateX(-200px) !important;
      max-width: 350px; }
      .notifications-header .notificacoes-popover:after, .notifications-header .notificacoes-popover:before {
        right: 19% !important; }
  .profile-header {
    padding-top: 10px; }
    .profile-header .profile-popover {
      transform: translateX(-150px) !important;
      display: flex; }
      .profile-header .profile-popover:after, .profile-header .profile-popover:before {
        right: 8% !important; }
    .profile-header .hidden-xs {
      margin-top: 10px;
      margin-left: 10px; } }

@media (max-width: 990px) {
  .hidden-sm {
    display: none; }
  .btn-nav-mobile {
    display: block !important; }
  .profile-header {
    display: inline-flex; }
  .btn-nav-mobile {
    display: block;
    margin-right: 13px !important; }
  .btn-logout {
    float: right; } }

@media (min-width: 992px) {
  .hidden-md {
    display: none; } }

@media (min-width: 790px) and (max-width: 990px) {
  .col-logo {
    flex: 0 0 30% !important;
    max-width: 30% !important; }
  .col-info {
    flex: 0 0 70% !important;
    max-width: 70% !important; } }

@media (min-width: 990px) {
  .col-logo {
    flex: 0 0 40% !important;
    max-width: 40% !important; }
  .col-info {
    flex: 0 0 60% !important;
    max-width: 60% !important; } }

@media (min-width: 990px) and (max-width: 1199px) {
  .navbar-item {
    padding: 15px 20px !important; } }

@media (max-width: 400px) {
  .btn-navbar {
    margin-left: 5px !important; }
  .token-mobile {
    margin-top: 5px; } }

.btn-dropdown {
  text-align: left !important;
  color: #262626 !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  letter-spacing: 0px !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  padding-left: 26px !important;
  padding-right: 16px;
  border-radius: 8px !important;
  background: #f8f8f8; }
  .btn-dropdown:hover {
    background: #C0C0C0; }
  .btn-dropdown:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    background: #C0C0C0 !important; }

.subDropDownShow {
  font-size: 13px !important;
  background: #f2f2f2;
  color: #888585 !important;
  padding-left: 40px !important; }
  .subDropDownShow:hover {
    text-decoration: none;
    background: #ebebeb !important; }
    .subDropDownShow:hover li {
      background: #033F7B; }

.subDropDownHide {
  display: none; }

.rt-tfoot .rt-td {
  background: #004C99 !important;
  color: #FFFFFF !important;
  padding: 0px 0px 0px 0px;
  letter-spacing: 0.5px; }
  .rt-tfoot .rt-td p {
    padding: 10px 0px 0px 0px; }
  .rt-tfoot .rt-td table {
    margin: 0;
    padding: 0;
    max-width: 100%;
    display: block;
    font-weight: 600 !important; }
    .rt-tfoot .rt-td table tbody {
      max-width: 100%;
      display: block; }
    .rt-tfoot .rt-td table td {
      max-width: 100%;
      display: block; }
    .rt-tfoot .rt-td table tr {
      padding: 10px 0px 10px 0px;
      display: block; }
      .rt-tfoot .rt-td table tr:nth-child(2) {
        background: #033F7B;
        border-top: 2px solid #FFFFFF; }

/*
CONTEÚDO

0. GERAL
1. BACKGROUNDS
2. TEXTS
3. BUTTONS
4. SECTIONS
5. FORMS
6. LOADER
7. TABLES
8. RODAL
*/
.clickable {
  cursor: pointer; }

.pull-right {
  float: right; }

body {
  font-family: "Inter" !important;
  overflow-x: hidden; }

.no-label {
  margin-top: 20px; }

.no-label-input label {
  display: none !important; }

.top-0 {
  top: 0; }

.overflow {
  overflow: auto; }

.input-group-text {
  background: white !important;
  color: #888585 !important; }

a:focus {
  outline: none !important; }

.loading-style i {
  color: #033F7B;
  font-size: 30px;
  background: transparent; }

.no-drop {
  pointer-events: all !important; }

.no-drop:hover {
  cursor: no-drop !important; }
  .no-drop:hover > div {
    cursor: no-drop !important;
    border-color: #e6e6e6 !important; }

.flex {
  display: flex;
  justify-content: space-between; }
  .flex p {
    margin-bottom: 0 !important; }
  .flex .text-flex {
    align-self: center; }
  .flex-start {
    align-self: flex-start; }

/* ------ 1. BACKGROUNDS -------- */
.page-container-border {
  padding: 20px;
  padding-bottom: 3rem;
  background: #ffffff;
  border: 20px solid #f8f8f8;
  border-radius: 6px;
  margin-top: 3rem;
  height: auto !important; }
  @media screen and (max-width: 979px) {
    .page-container-border {
      border: 10px solid #f8f8f8; } }

.default-background {
  background: #f8f8f8; }

@media screen and (min-width: 1280px) {
  .container {
    padding: 10px 40px; } }

@media screen and (max-width: 979px) {
  .container {
    height: 100% !important;
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media screen and (min-width: 1280px) {
  .containerBancoRedacao {
    padding: 10px 40px; } }

/* ------ 2. TEXTS -------- */
.danger {
  color: #D38155 !important; }

.success {
  color: #659C56 !important; }

.text-blue {
  color: #00ACAC !important; }

.gray {
  color: #888585 !important; }

.default {
  color: #004C99 !important; }

.text-title {
  color: #033F7B;
  font-size: 25px !important;
  padding: 30px 0px 20px 0px !important;
  border-bottom: 1px solid #f2f2f2;
  font-weight: 400 !important; }

.no-border-text-title {
  color: #033F7B;
  font-size: 25px !important;
  padding: 10px 0px 10px 0px !important;
  /*border-bottom: 1px solid $light-silver-color;*/
  font-weight: 400 !important; }

.text-title.no-border {
  border-bottom: 0; }

.text-subtitle {
  color: #60B2DD;
  font-size: 17px !important;
  text-transform: uppercase;
  letter-spacing: 0.2px; }

.text-information {
  padding-bottom: 10px;
  color: #9FA1A2; }
  .text-information span .text-bold {
    font-weight: 500; }
  .text-information span:not(.text-bold) {
    margin-right: 20px; }

/* ------ 3. BUTTONS -------- */
.btn {
  letter-spacing: 1px !important; }

.btn-disabled {
  color: #B1B2B4;
  font-size: 12px !important;
  font-weight: 600 !important;
  border: 1px solid #f2f2f2 !important;
  text-transform: uppercase;
  padding: 10px !important;
  opacity: 1 !important;
  margin-right: 20px;
  cursor: default;
  background: #FFFFFF; }

.bg-default-color-correcao.disabled, .bg-dark-default-color-correcao.disabled, .bg-default-color.disabled {
  background: #B1B2B4 !important;
  font-size: 12px !important;
  min-height: 45px;
  color: white;
  font-weight: 600 !important;
  border: 1px solid #888585 !important; }
  .bg-default-color-correcao.disabled:hover, .bg-dark-default-color-correcao.disabled:hover, .bg-default-color.disabled:hover {
    background: #B1B2B4 !important;
    cursor: no-drop; }

.bg-default-color.disabled {
  background: #B1B2B4 !important;
  font-size: 14px !important;
  min-height: 50px;
  color: white;
  font-weight: 600 !important;
  border: 1px solid #888585 !important; }
  .bg-default-color.disabled:hover {
    background: #B1B2B4 !important;
    cursor: no-drop; }

.btn-primary {
  font-size: 11px !important;
  border: none !important;
  padding: 0px 25px !important;
  min-height: 40px;
  background: #004C99 !important; }
  .btn-primary:hover {
    background: #033F7B !important; }

.btn-primary-grey {
  font-size: 11px !important;
  border: none !important;
  padding: 0px 25px !important;
  min-height: 40px;
  background: #004C99 !important;
  color: white; }
  .btn-primary-grey:hover {
    background: #033F7B !important; }

.btn-primary-grey.disabled {
  background: #B1B2B4 !important;
  font-size: 11px !important;
  padding: 0px 25px !important;
  min-height: 40px;
  color: white;
  font-weight: 600 !important;
  border: 1px solid #888585 !important; }
  .btn-primary-grey.disabled:hover {
    background: #B1B2B4 !important;
    cursor: no-drop; }

.btn-secondary {
  font-size: 11px !important;
  border: none !important;
  padding: 0px 25px !important;
  min-height: 40px;
  background: #DD7C60 !important; }
  .btn-secondary:hover {
    background: #E59E00 !important; }

.btn-default {
  font-size: 11px !important;
  min-height: 40px;
  border: 1px solid #c7c7c7 !important;
  background: white !important; }
  .btn-default:hover {
    background: white !important; }

.bg-primary-color {
  background-color: #004C99 !important; }

.bg-warning-color {
  background-color: #E59E00 !important; }

.bg-danger-color {
  background-color: #DB6969 !important; }

.bg-default-color {
  background: #004C99 !important;
  width: 100%;
  font-size: 14px !important;
  min-height: 50px;
  color: white;
  font-weight: 600 !important;
  border: none !important; }

.bg-default-color:HOVER {
  background: #8AC1E7 !important;
  width: 100%;
  font-size: 14px !important;
  color: white;
  font-weight: 600 !important; }

.bg-default-color-xs {
  background: #004C99 !important;
  min-width: 100px;
  min-height: 50px;
  font-size: 14px !important;
  color: white;
  font-weight: 600 !important;
  border: none !important; }

.bg-default-color-xs:HOVER {
  background: #033F7B !important;
  font-size: 14px !important;
  color: white;
  font-weight: 600 !important;
  border: none !important; }

.bg-dark-default-color-correcao {
  background: #033F7B !important;
  width: 100%;
  font-size: 12px !important;
  min-height: 45px;
  color: white;
  font-weight: 600 !important;
  border: none !important; }

.bg-default-color-correcao:HOVER {
  background: #004C99 !important; }

.bg-default-color-correcao {
  background: #004C99 !important;
  width: 100%;
  font-size: 12px !important;
  min-height: 45px;
  color: white;
  font-weight: 600 !important;
  border: none !important; }

.bg-default-color-pular-sair {
  background: #009458 !important;
  width: 100%;
  font-size: 12px !important;
  min-height: 45px;
  color: white;
  font-weight: 600 !important;
  border: none !important; }

.bg-default-color-pular-buscar-mais-um {
  background: #006400 !important;
  width: 100%;
  font-size: 12px !important;
  min-height: 45px;
  color: white;
  font-weight: 600 !important;
  border: none !important; }

@media (max-width: 767px) {
  .bg-dark-default-color-correcao {
    margin-bottom: 5px !important; } }

.bg-dark-default-color {
  background: #033F7B !important;
  width: 100%;
  font-size: 14px !important;
  min-height: 50px;
  color: white;
  font-weight: 600 !important;
  border: none !important; }

.bg-dark-default-color.disabled {
  background: #B1B2B4 !important; }

.bg-dark-default-color.disabled:hover {
  background: #B1B2B4 !important;
  cursor: no-drop; }

.bg-dark-default-color:hover {
  background: #60B2DD !important; }

.bg-warning-color-wh {
  background: #DD7C60 !important;
  width: 100%;
  min-height: 50px;
  font-size: 14px !important;
  color: white;
  font-weight: 600 !important; }

.bg-warning-color-wh:HOVER {
  background: #E59E00 !important;
  min-width: 100%;
  font-size: 14px !important;
  color: white;
  font-weight: 600 !important; }

.bg-warning-color {
  background: #E59E00 !important;
  min-width: 100%;
  font-size: 14px !important;
  color: white;
  min-height: 50px;
  font-weight: 600 !important; }

.bg-warning-color:hover {
  background: #DD7C60 !important;
  min-width: 100%;
  font-size: 14px !important;
  color: white;
  min-height: 50px;
  font-weight: 600 !important; }

.bg-dark-warning-color {
  background: #DD7C60 !important;
  font-size: 14px !important;
  color: white;
  min-height: 50px;
  font-weight: 600 !important; }

/* The container */
.input-group-custom {
  display: flex;
  justify-content: right;
  margin: 10px; }

.container-radio {
  margin-top: 3px;
  margin-bottom: 3px;
  position: relative;
  display: block; }

/* Hide the browser's default checkbox */
.container-radio input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 30px;
  height: 30px;
  cursor: pointer; }

/* Create a custom checkbox */
.checkmark {
  font-weight: bold;
  color: #666;
  border: 1px solid #ccc;
  text-align: center;
  padding: 2px 9px;
  margin-right: 4px;
  border-radius: 6px; }

/* On mouse-over, add a grey background color */
.container-radio:hover input ~ .checkmark {
  background-color: #ccc;
  cursor: pointer; }

/* When the checkbox is checked, add a blue background */
.container-radio input:checked ~ .checkmark {
  background-color: #004C99;
  color: white; }

.radio-button-custom {
  position: relative; }
  .radio-button-custom .label-input-situacoes {
    color: #333;
    font-size: 14px; }
    .radio-button-custom .label-input-situacoes:hover {
      cursor: pointer;
      color: #004C99 !important;
      font-weight: 600;
      transition: all .3s ease; }
    .radio-button-custom .label-input-situacoes:focus {
      color: #004C99 !important; }
  .radio-button-custom input {
    opacity: 0;
    z-index: 9999;
    position: absolute;
    top: 5px;
    transform: scale(1.4); }
    .radio-button-custom input:hover {
      cursor: pointer; }
  .radio-button-custom .checkmark-radio {
    position: absolute;
    top: 4px;
    left: -3px;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
    opacity: 2; }
    .radio-button-custom .checkmark-radio:hover {
      background: #ccc; }
    .radio-button-custom .checkmark-radio:after {
      content: "";
      position: absolute;
      display: none; }
  .radio-button-custom input:checked ~ .checkmark-radio {
    border-color: #004C99;
    border-width: 0px;
    background-color: #004C99; }
  .radio-button-custom input:hover .label-input-situacoes {
    color: #004C99; }

.destaque-value-0 {
  border-color: #004C99;
  border-width: 0px;
  background-color: #004C99;
  color: white; }
  .destaque-value-0:hover {
    border-color: #004C99;
    border-width: 0px;
    background-color: #004C99 !important;
    color: white; }

.loading-style .loading {
  max-width: 50px;
  left: 40%;
  position: static;
  margin-top: 15%;
  margin-bottom: 15%; }

.-loading-inner .loading-style .loading {
  margin: 0 !important;
  position: relative !important;
  left: 0;
  top: -50px; }

.error-message {
  margin-top: 20px;
  font-size: 600;
  color: #a5a5a5;
  letter-spacing: 0.5px; }

.table-gabarito .rt-thead {
  color: #033F7B !important; }

.table-gabarito .situacao-modal-gabarito {
  height: 40px; }
  .table-gabarito .situacao-modal-gabarito span {
    text-transform: uppercase;
    font-weight: 600;
    border: 2px solid #9FA1A2;
    padding: 4px;
    border-radius: 6px;
    line-height: 37px; }

.rt-noData {
  display: none !important; }

.table-empty-block {
  display: block;
  left: 50%;
  top: 50%;
  pointer-events: none;
  padding: 20px;
  color: rgba(0, 0, 0, 0.5); }

.count-navbar {
  font-size: 13px;
  color: #888585;
  margin-top: 0.30rem; }
  .count-navbar .countdown-style {
    font-weight: 600;
    margin-right: 15px; }

.rodal {
  position: absolute !important; }

.rodal-mask {
  position: fixed !important; }

.modal-80 .rodal-dialog {
  width: 80% !important;
  height: 100%; }

@media (max-width: 500px) {
  .modal-80 .rodal-dialog {
    height: 400px !important; } }

.plagius-modal .rodal-dialog {
  width: 80vw !important; }

.modal-gabarito .rodal {
  margin-top: 0px; }

.modal-gabarito .rodal-dialog {
  position: absolute !important;
  top: 50px;
  width: 350px !important;
  min-height: 500px !important; }
  .modal-gabarito .rodal-dialog .rt-thead {
    color: #888585;
    text-align: left;
    font-weight: 700; }
  .modal-gabarito .rodal-dialog .-headerGroups .rt-th {
    min-width: 100% !important; }
  .modal-gabarito .rodal-dialog .pagination-bottom {
    display: none; }

.modal-dados-avaliador .rodal-dialog {
  width: 80% !important;
  min-height: 700px !important;
  position: absolute !important;
  margin-top: 150px; }

.modal-supervisor .rodal-dialog {
  height: 520px !important;
  width: 80% !important;
  border: 25px solid #eee;
  animation-duration: 300ms;
  padding: 40px 15px; }

.manutencao {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin-bottom: 3%; }
  .manutencao .logo-manutencao img {
    max-width: 200px;
    margin-bottom: 30px; }
  .manutencao .img-manutencao {
    margin-top: 20px;
    max-width: 700px; }
  .manutencao figcaption {
    color: #033F7B; }
    .manutencao figcaption h5 {
      color: #004C99; }

input[type="date"] {
  padding-right: 12px; }

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background-image: url("../icons/calendar-alt-solid.svg");
  width: 3px;
  margin-left: 5px; }

.excel-icon {
  background-image: url("../icons/excel-icon.svg");
  background-repeat: no-repeat;
  background-size: 28px;
  border: 1px solid #cccccc !important;
  background-position: 47% 50%;
  background-color: #fff;
  line-height: 100px;
  font-size: 8px;
  width: 30px;
  height: 30px;
  text-transform: uppercase; }

.pre {
  font-family: inherit;
  margin: 0px;
  white-space: pre-wrap; }

.font-weight-200 {
  font-weight: 200; }

.font-weight-400 {
  font-weight: 400; }

.font-weight-600 {
  font-weight: 600; }

.font-weight-800 {
  font-weight: 800; }

.primary-label {
  font-weight: 600;
  color: #60B2DD;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 16px; }

.card-header-ocorrencia-respondida {
  background: #9AC75C !important; }

.card-ocorrencia p {
  font-size: 14px; }

#ocorrencia-respondida {
  color: #888585; }

.card-header-ocorrencia {
  background: #60B2DD;
  padding: 5px 20px !important;
  color: white !important;
  font-weight: 600;
  font-size: 14px;
  text-align: left; }
  .card-header-ocorrencia:hover {
    cursor: pointer; }
  .card-header-ocorrencia:focus {
    outline: none; }

.card-ocorrencia-title {
  font-weight: 600;
  color: #DB6969; }

.card-ocorrencia button.break-text {
  font-size: 13px !important;
  white-space: normal; }

.ocorrencia-pergunta {
  background: #f2f2f2;
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px; }
  .ocorrencia-pergunta p {
    margin-bottom: 0;
    color: #4A4747;
    font-weight: 500; }

.ocorrencia-resposta {
  background: #E6EED5;
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px; }
  .ocorrencia-resposta p {
    margin-bottom: 0;
    color: #4A4747;
    font-weight: 500; }

.ocorrencia-historico {
  margin-top: 20px;
  background: #f2f2f2;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #888585;
  min-height: 300px;
  max-height: 450px;
  overflow-y: scroll; }

.card-historico-title {
  color: #004C99;
  font-weight: 600;
  text-align: center;
  width: 100%;
  font-size: 14px;
  margin-bottom: 20px; }

.card-historico-subtitle {
  text-transform: uppercase;
  font-weight: 600;
  color: #033F7B;
  font-size: 12px !important; }

.historico-responsavel {
  background: white;
  padding: 5px;
  border-radius: 6px;
  box-shadow: 0 0 4px #ccc; }

.historico-responsavel-question {
  background: white;
  border-radius: 6px;
  padding: 10px;
  font-size: 14px; }
  .historico-responsavel-question-resp {
    background: #E6EED5 !important;
    padding: 10px;
    border-radius: 6px;
    font-size: 14px; }

.modalConfirmacao {
  padding-left: 10px; }

.conteudoConfirmacao {
  color: #888585;
  font-size: 16px;
  padding-top: 10px;
  margin-bottom: 30px; }

.tituloConfirmacao {
  color: #023F76;
  font-size: 23px; }

.botaoConfirmacao {
  margin-right: 10px !important; }

.botoesConfirmacao {
  font-size: 16px;
  margin-bottom: 20px; }

.boxTitulo {
  border-bottom: solid #8AC1E7;
  border-width: 1px;
  padding-bottom: 15px; }

.ReactTable .pagination-bottom .-pagination .-pageSizeOptions {
  position: absolute;
  left: 0;
  margin-top: 0; }
  .ReactTable .pagination-bottom .-pagination .-pageSizeOptions select {
    height: 39px;
    vertical-align: middle;
    color: #218ec6;
    font-weight: 600; }

.ReactTable .pagination-bottom .-pagination button {
  max-width: 50px;
  margin: 0 !important;
  color: #FFFFFF !important;
  background: #004C99; }
  .ReactTable .pagination-bottom .-pagination button:disabled {
    display: none; }
  .ReactTable .pagination-bottom .-pagination button:hover {
    background: #033F7B !important; }

.ReactTable .pagination-bottom .-pagination .-previous {
  max-width: 50px; }

.ReactTable .pagination-bottom .-pagination .-next {
  max-width: 50px; }

@media (max-width: 769px) {
  .ReactTable .-pagination .-pageSizeOptions {
    position: initial !important; } }

@media (max-width: 490px) {
  .ReactTable .-center {
    margin-top: 10px !important;
    margin-bottom: 10px !important; } }

.table-lista-ocorrencias .rt-resizable-header {
  white-space: normal;
  text-align: left; }

.table-lista-ocorrencias .rt-resizable-header-content {
  color: #033F7B !important; }

.table-lista-ocorrencias .rt-th {
  text-align: left; }
  .table-lista-ocorrencias .rt-th input {
    max-width: 160px; }

.table-lista-ocorrencias .rt-td {
  white-space: normal;
  text-align: left;
  border-right: 1px solid #414141; }

.table-relatorios .rt-thead .rt-tr .rt-th {
  font-weight: 700;
  color: #0090cb; }

.table-relatorios .rt-resizable-header {
  white-space: normal;
  text-align: left; }

.table-relatorios .rt-resizable-header-content {
  color: #033F7B !important; }

.table-relatorios .rt-th {
  text-align: left; }
  .table-relatorios .rt-th:nth-child(5) {
    text-align: right; }
  .table-relatorios .rt-th:nth-child(6) {
    text-align: right; }
  .table-relatorios .rt-th input {
    max-width: 160px; }

.table-relatorios .rt-td {
  white-space: normal;
  border-right: 1px solid #414141;
  text-align: left;
  padding-left: 15px; }
  .table-relatorios .rt-td:nth-child(5) {
    text-align: right;
    padding-right: 20px; }
  .table-relatorios .rt-td:nth-child(6) {
    text-align: right;
    padding-right: 20px; }

.table-relatorios-notas-pre-teste .rt-thead .rt-tr .rt-th {
  font-weight: 700;
  color: #0090cb; }

.table-relatorios-notas-pre-teste .rt-resizable-header {
  white-space: normal;
  text-align: left; }

.table-relatorios-notas-pre-teste .rt-resizable-header-content {
  color: #033F7B !important; }

.table-relatorios-notas-pre-teste .rt-th {
  text-align: left; }
  .table-relatorios-notas-pre-teste .rt-th:nth-child(n+6):nth-child(-n+11) {
    text-align: right;
    padding-right: 20px; }
  .table-relatorios-notas-pre-teste .rt-th:nth-child(n+13):nth-child(-n+18) {
    text-align: right;
    padding-right: 20px; }
  .table-relatorios-notas-pre-teste .rt-th:nth-child(n+20):nth-child(-n+28) {
    text-align: right;
    padding-right: 20px; }
  .table-relatorios-notas-pre-teste .rt-th input {
    max-width: 160px; }

.table-relatorios-notas-pre-teste .rt-td {
  white-space: normal;
  border-right: 1px solid #414141;
  text-align: left;
  padding-left: 15px; }
  .table-relatorios-notas-pre-teste .rt-td:nth-child(4) {
    text-align: right;
    padding-right: 20px; }
  .table-relatorios-notas-pre-teste .rt-td:nth-child(n+6):nth-child(-n+11) {
    text-align: right;
    padding-right: 20px; }
  .table-relatorios-notas-pre-teste .rt-td:nth-child(n+13):nth-child(-n+18) {
    text-align: right;
    padding-right: 20px; }
  .table-relatorios-notas-pre-teste .rt-td:nth-child(n+20):nth-child(-n+28) {
    text-align: right;
    padding-right: 20px; }

.tabela-coordenacao-geral .rt-resizable-header {
  white-space: normal;
  text-align: left; }
  .tabela-coordenacao-geral .rt-resizable-header:nth-child(n+2):nth-child(-n+8) {
    text-align: right;
    padding-right: 20px; }

.tabela-coordenacao-geral .rt-resizable-header-content {
  color: #033F7B !important; }

.tabela-coordenacao-geral .rt-th input {
  max-width: 160px; }

.tabela-coordenacao-geral .rt-td:nth-child(1) {
  text-align: left;
  padding-left: 20px; }

.tabela-coordenacao-geral .rt-td:nth-child(n+2):nth-child(-n+8) {
  text-align: right;
  padding-right: 20px; }

.tabela-coordenacao-geral .rt-tfoot .rt-td {
  border-right: 1px solid #3E7DAF !important; }
  .tabela-coordenacao-geral .rt-tfoot .rt-td:nth-child(1) {
    text-align: left;
    padding-left: 20px; }
  .tabela-coordenacao-geral .rt-tfoot .rt-td:nth-child(n+2):nth-child(-n+8) {
    text-align: right;
    padding-right: 20px; }

.tabela-auditoria-dashboard .rt-thead .rt-th {
  color: #004C99;
  font-weight: 700; }

.tabela-auditoria-dashboard .rt-td:nth-child(1) {
  text-align: left;
  padding-left: 20px; }

.tabela-auditoria-dashboard .rt-td:nth-child(n+2):nth-child(-n+8) {
  text-align: right;
  padding-right: 20px; }

.tabela-auditoria-dashboard .rt-tfoot .rt-td {
  border-right: 1px solid #3E7DAF !important; }
  .tabela-auditoria-dashboard .rt-tfoot .rt-td:nth-child(1) {
    text-align: left;
    padding-left: 20px; }
  .tabela-auditoria-dashboard .rt-tfoot .rt-td:nth-child(n+2):nth-child(-n+5) {
    text-align: right;
    padding-right: 20px; }

.tabela-aproveitamento-notas-polo .rt-resizable-header {
  white-space: normal;
  text-align: left; }
  .tabela-aproveitamento-notas-polo .rt-resizable-header:nth-child(n+3):nth-child(-n+10) {
    text-align: right;
    padding-right: 20px; }

.tabela-aproveitamento-notas-polo .rt-resizable-header-content {
  color: #033F7B !important; }

.tabela-aproveitamento-notas-polo .rt-th input {
  max-width: 160px; }

.tabela-aproveitamento-notas-polo .rt-td:nth-child(1) {
  text-align: left;
  padding-left: 20px; }

.tabela-aproveitamento-notas-polo .rt-td:nth-child(2) {
  text-align: left;
  padding-left: 20px; }

.tabela-aproveitamento-notas-polo .rt-td:nth-child(n+3):nth-child(-n+10) {
  text-align: right;
  padding-right: 20px; }

.tabela-aproveitamento-notas-polo .rt-tfoot {
  border-right: 1px solid #3E7DAF !important; }
  .tabela-aproveitamento-notas-polo .rt-tfoot .rt-td:nth-child(1) {
    text-align: left;
    padding-left: 20px; }
  .tabela-aproveitamento-notas-polo .rt-tfoot .rt-td:nth-child(2) {
    text-align: left;
    padding-left: 20px; }
  .tabela-aproveitamento-notas-polo .rt-tfoot:nth-child(n+3):nth-child(-n+10) {
    text-align: right; }

.alerta-geral-tabelas-novas {
  font-size: 12px;
  background: #DD7C60;
  color: #FFFFFF;
  border-radius: 4px;
  min-width: 40px;
  margin: auto;
  padding: 3px 0;
  float: right;
  text-align: center; }

.tabela-time-relatorio .rt-resizable-header {
  white-space: normal;
  text-align: left; }
  .tabela-time-relatorio .rt-resizable-header:nth-child(n+5):nth-child(-n+10) {
    text-align: right;
    padding-right: 20px; }

.tabela-time-relatorio .rt-resizable-header-content {
  color: #033F7B !important; }

.tabela-time-relatorio .rt-th input {
  max-width: 160px; }

.tabela-time-relatorio .rt-td:nth-child(1) {
  text-align: left;
  padding-left: 20px; }

.tabela-time-relatorio .rt-td:nth-child(2) {
  text-align: left;
  padding-left: 20px; }

.tabela-time-relatorio .rt-td:nth-child(3) {
  text-align: left;
  padding-left: 20px; }

.tabela-time-relatorio .rt-td:nth-child(4) {
  text-align: left;
  padding-left: 20px; }

.tabela-time-relatorio .rt-td:nth-child(n+5):nth-child(-n+10) {
  text-align: right;
  padding-right: 20px; }

.tabela-time-relatorio .rt-tfoot .rt-td:nth-child(1n) {
  border-right: 2px solid #FFFFFF !important; }

.tabela-time-relatorio-aproveitamento .rt-resizable-header {
  white-space: normal;
  text-align: left; }
  .tabela-time-relatorio-aproveitamento .rt-resizable-header:nth-child(n+5):nth-child(-n+10) {
    text-align: right;
    padding-right: 20px; }

.tabela-time-relatorio-aproveitamento .rt-resizable-header-content {
  color: #033F7B !important; }

.tabela-time-relatorio-aproveitamento .rt-th input {
  max-width: 160px; }

.tabela-time-relatorio-aproveitamento .rt-td:nth-child(1) {
  text-align: left;
  padding-left: 20px; }

.tabela-time-relatorio-aproveitamento .rt-td:nth-child(2) {
  text-align: left;
  padding-left: 20px; }

.tabela-time-relatorio-aproveitamento .rt-td:nth-child(3) {
  text-align: left;
  padding-left: 20px; }

.tabela-time-relatorio-aproveitamento .rt-td:nth-child(4) {
  text-align: right;
  padding-left: 20px; }

.tabela-time-relatorio-aproveitamento .rt-td:nth-child(n+5):nth-child(-n+10) {
  text-align: right;
  padding-right: 20px; }

.tabela-time-relatorio-aproveitamento .rt-tfoot {
  border-right: 1px solid #3E7DAF !important; }
  .tabela-time-relatorio-aproveitamento .rt-tfoot .rt-td:nth-child(1) {
    text-align: left;
    padding-left: 20px; }
  .tabela-time-relatorio-aproveitamento .rt-tfoot .rt-td:nth-child(2) {
    text-align: left;
    padding-left: 20px; }
  .tabela-time-relatorio-aproveitamento .rt-tfoot .rt-td:nth-child(3) {
    text-align: left;
    padding-left: 20px; }
  .tabela-time-relatorio-aproveitamento .rt-tfoot .rt-td:nth-child(4) {
    text-align: right;
    padding-left: 20px; }
  .tabela-time-relatorio-aproveitamento .rt-tfoot:nth-child(n+5):nth-child(-n+10) {
    text-align: right;
    padding-right: 20px; }

.tabela-time-relatorio-distribuicao .rt-resizable-header {
  white-space: normal;
  text-align: left; }
  .tabela-time-relatorio-distribuicao .rt-resizable-header:nth-child(n+5):nth-child(-n+10) {
    text-align: right;
    padding-right: 20px; }

.tabela-time-relatorio-distribuicao .rt-resizable-header-content {
  color: #033F7B !important; }

.tabela-time-relatorio-distribuicao .rt-tbody .rt-td:nth-child(1) {
  text-align: left;
  padding-left: 20px; }

.tabela-time-relatorio-distribuicao .rt-tbody .rt-td:nth-child(2) {
  text-align: left;
  padding-left: 20px; }

.tabela-time-relatorio-distribuicao .rt-tbody .rt-td:nth-child(3) {
  text-align: left;
  padding-left: 20px; }

.tabela-time-relatorio-distribuicao .rt-tbody .rt-td:nth-child(4) {
  text-align: left;
  padding-left: 20px; }

.tabela-time-relatorio-distribuicao .rt-tbody .rt-td:nth-child(n+5):nth-child(-n+10) {
  text-align: right;
  padding-right: 20px; }

.tabela-time-relatorio-distribuicao .rt-tfoot .rt-td:nth-child(1n) {
  border-right: 1px solid #3E7DAF !important; }

.tabela-time-relatorio-distribuicao .rt-tfoot .rt-td:nth-child(n+5):nth-child(-n+10) {
  text-align: right; }
  .tabela-time-relatorio-distribuicao .rt-tfoot .rt-td:nth-child(n+5):nth-child(-n+10) td {
    padding-right: 20px; }

.tabela-time-relatorio-distribuicao .alerta-situacao-time {
  font-size: 12px;
  background: #DD7C60;
  color: #FFFFFF;
  border-radius: 4px;
  min-width: 40px;
  margin: auto;
  float: right;
  text-align: center; }

.chat-popup-button {
  position: fixed;
  top: 15px;
  right: -40px;
  background: #0062a0;
  cursor: pointer;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  z-index: 999;
  box-shadow: 0 0 20px #ccc;
  transition: 0.4s ease-in-out;
  animation: 0.4s ease-in-out;
  border: none;
  line-height: 20px;
  padding: 6px 6px;
  color: white;
  display: flex !important;
  align-items: center; }
  .chat-popup-button i {
    font-size: 25px;
    color: #FFFFFF; }
  .chat-popup-button span {
    font-weight: 600;
    font-size: 12px; }
  .chat-popup-button:hover {
    transition: 0.2s ease-in-out;
    animation: 0.2s ease-in-out;
    background: #004C99;
    box-shadow: 0 0 20px #ccc;
    right: 0px; }

.chat-popup-counter {
  position: absolute;
  top: -6px;
  left: -6px;
  padding: 5px;
  font-size: 12px;
  margin: 0;
  border-radius: 50px;
  background: #DB6969;
  color: #fff;
  width: 20px;
  height: 20px;
  line-height: 9px;
  font-weight: 600;
  text-align: center;
  z-index: 999999999999; }

.__talkjs_launcher {
  background-color: #033F7B !important;
  color: #fff !important; }

.chat-conversation-users {
  position: fixed;
  right: 20px;
  top: 10px;
  z-index: 1000;
  height: 500px;
  background: #f9f9f9;
  width: 350px;
  border-radius: 5px;
  border: 1px solid #dce0e0; }
  .chat-conversation-users-header {
    height: 62px;
    padding: 10px;
    background: #f1f2f2;
    border-bottom: 1px solid #dce0e0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .chat-conversation-users-header button {
      border: none;
      background: transparent;
      -webkit-transition: color 0.2s;
      transition: color 0.2s; }
      .chat-conversation-users-header button:hover {
        cursor: pointer;
        color: white;
        -webkit-transition: color 0.2s;
        transition: color 0.2s; }
    .chat-conversation-users-header span {
      display: flex;
      align-items: center; }
    .chat-conversation-users-header p {
      margin-bottom: 0;
      padding-left: 10px;
      font-weight: bold; }
  .chat-conversation-users-body {
    display: grid;
    max-height: 89%;
    overflow-y: scroll; }
    .chat-conversation-users-body button {
      border: none;
      position: relative;
      color: #313131;
      padding: 15px 0;
      padding-left: 0px;
      background: #f9f9f9;
      text-align: left;
      padding-left: 20px;
      font-size: 14px;
      display: flex;
      align-items: center;
      -webkit-transition: background 0.1s;
      transition: background 0.1s; }
      .chat-conversation-users-body button .chat-unread {
        width: 10px;
        height: 10px;
        position: absolute;
        background: #6bccfd;
        border-radius: 50%;
        border: 1px solid #58c0f5;
        left: 20px;
        bottom: 10px;
        box-shadow: 0px 0px 15px 2px #81b7d2; }
      .chat-conversation-users-body button.active {
        color: #fff;
        background: #004C99 !important; }
        .chat-conversation-users-body button.active .user-chat-pic {
          border-color: white; }
      .chat-conversation-users-body button:hover {
        background: #218ec6;
        color: white;
        cursor: pointer;
        -webkit-transition: background 0.1s;
        transition: background 0.1s; }
        .chat-conversation-users-body button:hover .user-chat-pic {
          border-color: white; }
      .chat-conversation-users-body button .user-chat-pic {
        height: 50px;
        width: 50px;
        border: 1px solid #313131;
        border-radius: 50%; }
      .chat-conversation-users-body button .user-chat-details {
        margin-left: 15px; }
      .chat-conversation-users-body button .exclamation-chat {
        color: #fff;
        position: absolute;
        right: 0;
        background: #E59E00;
        padding: 5px 10px 5px 20px;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px; }
    .chat-conversation-users-body .chat-line {
      width: 90%;
      margin: 0;
      border-width: 2px; }

.chat-conversation-main {
  margin: 10px 30px;
  height: 500px;
  position: fixed;
  width: 350px;
  right: 350px;
  top: 0;
  z-index: 100; }

.__talkjs_popup {
  top: 15px !important;
  right: 40px !important;
  width: 350px; }

.show-button {
  position: fixed;
  right: 395px;
  z-index: 9999;
  top: 30px;
  border: none; }
  .show-button:hover {
    cursor: pointer; }

.modelo-tooltip {
  background: white; }

@media (max-width: 740px) {
  .chat-popup-button {
    top: 80px; }
  .chat-conversation-users {
    top: 0px;
    width: 100%;
    left: 0px;
    z-index: 101;
    height: 100%; }
  .inbox-button {
    position: fixed;
    right: 30px !important; }
  .hide {
    display: none; }
  .chat-conversation-main {
    width: 100%;
    margin: 0px;
    height: 100%;
    left: 0px; }
  .show-button {
    position: fixed;
    right: 30px;
    top: 20px; } }

.chat-conversation-users-body {
  overflow-y: scroll; }

.legends-list {
  list-style: none;
  -moz-column-count: 3;
  -moz-column-gap: 20px;
  -webkit-column-count: 3;
  -webkit-column-gap: 20px;
  column-count: 3;
  column-gap: 20px; }
  .legends-list-item {
    position: relative;
    font-style: italic;
    font-weight: 200; }
    .legends-list-item-icon {
      margin-right: 0.5rem; }

.rodal,
.rodal-mask {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  position: fixed !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 10px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.rodal-dialog {
  display: flex;
  margin: auto;
  height: auto !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
  font-family: inherit;
  font-size: 1rem;
  padding: 1.25em;
  border-radius: 0.3125em;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative !important;
  width: 32em;
  max-width: 100%; }

.non-fixed-rodal {
  position: absolute !important; }

.essay-details-grid {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 1rem;
  margin-top: 1rem; }
  .essay-details-grid .essay-details-image {
    grid-column: 1 / 2; }
  .essay-details-grid .essay-details-info {
    grid-column: 2 / -1; }
  .essay-details-grid .nova-nota {
    margin: 2rem; }

.question-title {
  font-size: 18px !important;
  font-weight: 600;
  color: #4A4747; }

.question-subtitle {
  font-size: 12px;
  font-weight: 200; }

.question-box {
  color: #4A4747;
  border-radius: 2px;
  padding: 1rem;
  width: 100%;
  margin-top: 1rem;
  background: #f2f2f2; }

.answer-title {
  font-size: 18px !important;
  font-weight: 600;
  color: #4A4747;
  text-align: right; }

.answer-subtitle {
  font-size: 12px;
  font-weight: 200;
  text-align: right;
  display: flex;
  justify-content: flex-end; }

.answer-box {
  color: #4A4747;
  border-radius: 2px;
  padding: 1rem;
  width: 100%;
  margin-top: 1rem;
  background: #d0e5f1;
  margin-left: auto; }

.box-highlight {
  border: 2px solid #60B2DD;
  background: white;
  padding: 1rem 3rem;
  border-radius: 5px;
  width: 90%;
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center; }

.page-content-correcao {
  padding: 15px; }

.card .collapse.hidden {
  max-height: 0;
  transition: max-height .1s ease-out;
  overflow: hidden; }

.card .collapse.show {
  max-height: 1500px;
  transition: max-height .2s ease-in;
  overflow: scroll !important; }

.collapse:not(.show) {
  display: block !important; }

@media (max-width: 1600px) {
  .hidden-5 {
    display: none; } }

@media (max-width: 1300px) {
  .hidden-6 {
    display: none; } }

@media (max-width: 1150px) {
  .hidden-7 {
    display: none; } }

@media (max-width: 990px) {
  .hidden-8 {
    display: none; } }

.ferramenta-text {
  font-size: 8px;
  color: #888585;
  text-transform: uppercase;
  text-align: center;
  padding-top: 5px;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 0;
  width: 52px; }

.correcao {
  border: 5px solid rgba(0, 0, 0, 0.125);
  border-top: 0;
  background: #f2f2f2; }
  .correcao .correcao-ferramentas {
    background: #fff;
    box-shadow: 0 0 15px #ccc;
    position: sticky;
    top: 0;
    z-index: 1;
    display: grid; }
    .correcao .correcao-ferramentas .correcao-ferramentas-lista {
      display: inline-flex;
      padding: 10px;
      margin-bottom: 0;
      z-index: 999;
      border-top: 5px solid #D1D1D1; }
      .correcao .correcao-ferramentas .correcao-ferramentas-lista li {
        padding: 8px; }
        .correcao .correcao-ferramentas .correcao-ferramentas-lista li .btn-ferramentas {
          width: 52px;
          height: 45px;
          padding: 0px;
          border: 1px solid #ccc;
          position: relative; }
          .correcao .correcao-ferramentas .correcao-ferramentas-lista li .btn-ferramentas:hover {
            border: 1px solid #ccc; }
          .correcao .correcao-ferramentas .correcao-ferramentas-lista li .btn-ferramentas:focus {
            box-shadow: none !important;
            border: 1px solid #888585 !important;
            box-shadow: none;
            border: none; }
        .correcao .correcao-ferramentas .correcao-ferramentas-lista li .select {
          background-image: url("../icons/select.svg");
          background-repeat: no-repeat;
          background-size: 20px;
          background-position: 47% 50%;
          background-color: #fff;
          line-height: 30px;
          padding-top: 40px;
          font-size: 8px;
          color: #888585;
          text-transform: uppercase; }
        .correcao .correcao-ferramentas .correcao-ferramentas-lista li .brush {
          background-image: url("../icons/brush.svg");
          background-repeat: no-repeat;
          background-size: 25px;
          background-position: center;
          background-color: #fff;
          line-height: 30px;
          padding-top: 40px;
          font-size: 8px;
          color: #888585;
          text-transform: uppercase; }
        .correcao .correcao-ferramentas .correcao-ferramentas-lista li .ortografia svg {
          width: 65%; }
        .correcao .correcao-ferramentas .correcao-ferramentas-lista li .pencil {
          background-image: url("../icons/pen.svg");
          background-repeat: no-repeat;
          background-size: 22px;
          background-position: center;
          background-color: #fff;
          line-height: 30px;
          padding-top: 40px;
          font-size: 8px;
          color: #888585;
          text-transform: uppercase; }
        .correcao .correcao-ferramentas .correcao-ferramentas-lista li .zoom {
          background-image: url("../icons/zoomIn.svg");
          background-repeat: no-repeat;
          background-size: 35px;
          background-position: center;
          background-color: #fff;
          line-height: 30px;
          padding-top: 40px;
          font-size: 8px;
          color: #888585;
          text-transform: uppercase; }
        .correcao .correcao-ferramentas .correcao-ferramentas-lista li .zoom- {
          background-image: url("../icons/zoomOut.svg");
          background-repeat: no-repeat;
          background-size: 35px;
          background-position: center;
          background-color: #fff;
          line-height: 30px;
          padding-top: 40px;
          font-size: 8px;
          color: #888585;
          text-transform: uppercase; }
        .correcao .correcao-ferramentas .correcao-ferramentas-lista li .trash {
          background-image: url("../icons/Eraser.svg");
          background-repeat: no-repeat;
          background-size: 35px;
          background-position: center;
          background-color: #fff;
          line-height: 30px;
          padding-top: 40px;
          font-size: 8px;
          color: #888585;
          text-transform: uppercase; }
        .correcao .correcao-ferramentas .correcao-ferramentas-lista li .reset {
          background-image: url("../icons/focus.svg");
          background-repeat: no-repeat;
          background-size: 26px;
          background-position: center;
          background-color: #fff;
          line-height: 30px;
          padding-top: 40px;
          font-size: 8px;
          color: #888585;
          text-transform: uppercase; }
        .correcao .correcao-ferramentas .correcao-ferramentas-lista li .download {
          background-image: url("../icons/download.svg");
          background-repeat: no-repeat;
          background-size: 26px;
          background-position: center;
          background-color: #fff;
          line-height: 30px;
          padding-top: 40px;
          font-size: 8px;
          color: #888585;
          text-transform: uppercase; }
        .correcao .correcao-ferramentas .correcao-ferramentas-lista li .rotate {
          background-image: url("../icons/rotate.svg");
          background-repeat: no-repeat;
          background-size: 26px;
          background-position: center;
          background-color: #fff;
          line-height: 30px;
          padding-top: 40px;
          font-size: 8px;
          color: #888585;
          text-transform: uppercase; }
        .correcao .correcao-ferramentas .correcao-ferramentas-lista li .active {
          box-shadow: none !important;
          border: 1px solid #888585 !important;
          box-shadow: none;
          border: none; }
        .correcao .correcao-ferramentas .correcao-ferramentas-lista li #brightness {
          position: relative;
          cursor: pointer; }
      @media screen and (max-width: 979px) {
        .correcao .correcao-ferramentas .correcao-ferramentas-lista {
          padding: 0 0 15px 0; }
          .correcao .correcao-ferramentas .correcao-ferramentas-lista li .btn-ferramentas {
            width: 42px;
            height: 35px; }
          .correcao .correcao-ferramentas .correcao-ferramentas-lista li .select {
            background-image: url("../icons/select.svg");
            background-repeat: no-repeat;
            background-size: 20px;
            background-position: 47% 50%;
            background-color: #fff;
            line-height: 30px;
            padding-top: 40px;
            font-size: 7px;
            color: #888585;
            text-transform: uppercase; }
          .correcao .correcao-ferramentas .correcao-ferramentas-lista li .zoom {
            background-image: url("../icons/zoomIn.svg");
            background-repeat: no-repeat;
            background-size: 20px;
            background-position: center;
            background-color: #fff;
            line-height: 30px;
            padding-top: 40px;
            font-size: 7px;
            color: #888585;
            text-transform: uppercase; }
          .correcao .correcao-ferramentas .correcao-ferramentas-lista li .zoom- {
            background-image: url("../icons/zoomOut.svg");
            background-repeat: no-repeat;
            background-size: 20px;
            background-position: center;
            background-color: #fff;
            line-height: 30px;
            padding-top: 40px;
            font-size: 7px;
            color: #888585;
            text-transform: uppercase; }
          .correcao .correcao-ferramentas .correcao-ferramentas-lista li .reset {
            background-image: url("../icons/focus.svg");
            background-repeat: no-repeat;
            background-size: 20px;
            background-position: center;
            background-color: #fff;
            line-height: 30px;
            padding-top: 40px;
            font-size: 7px;
            color: #888585;
            text-transform: uppercase; }
          .correcao .correcao-ferramentas .correcao-ferramentas-lista li .download {
            background-image: url("../icons/download.svg");
            background-repeat: no-repeat;
            background-size: 20px;
            background-position: center;
            background-color: #fff;
            line-height: 30px;
            padding-top: 40px;
            font-size: 7px;
            color: #888585;
            text-transform: uppercase; } }
  .correcao .correcao-imagem {
    overflow: hidden; }

.img-ferramentas-correcao {
  max-width: 35px;
  margin-right: 10px; }

.slidecontainer {
  background: #fff;
  padding: 7px;
  padding-left: 20px;
  padding-right: 25px;
  box-shadow: 0 5px 15px #ccc;
  border-radius: 5px;
  flex-direction: row;
  display: flex;
  align-items: center; }
  .slidecontainer input {
    margin-top: 2px;
    height: 6px; }

#container.grab {
  cursor: grab; }

#container.grabbing {
  cursor: grabbing; }

#container.brush {
  cursor: url("../icons/brush.png"), default; }

#container.pencil {
  cursor: url("../icons/pencil.png"), default; }

#container.trash {
  cursor: crosshair; }

#container.default {
  cursor: default; }

.count-arrow {
  position: absolute;
  top: -5px;
  padding: 0px;
  font-size: 10px;
  background: #9F51D9;
  width: 20px;
  height: 20px;
  line-height: 18px;
  border-radius: 20px;
  left: -10px;
  color: white;
  font-weight: 600; }

.avaliador-table-labels {
  text-align: start; }

/* tabela de historico */
.tabela-historico {
  margin-bottom: 10px;
  margin-top: 10px; }
  .tabela-historico .avaliador-title-label {
    color: #228ec6;
    float: left;
    margin-left: 5px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 400; }
  .tabela-historico .avaliador-name-label {
    color: #807f7f;
    float: left;
    margin-left: 5px;
    font-size: 13px;
    font-weight: 400; }
  .tabela-historico table {
    width: 100%; }
  .tabela-historico .table-data {
    border-top: 2px solid #f5f5f5;
    border-left: 2px solid #f5f5f5;
    text-align: center;
    width: 15%;
    font-weight: 400;
    font-size: 13px; }
  .tabela-historico .table-data-border {
    border-top: 2px solid #f5f5f5;
    border-left: 2px solid #f5f5f5 !important;
    border-right: 2px solid #f5f5f5 !important;
    text-align: center;
    width: 15%;
    font-weight: 400;
    font-size: 13px; }
  .tabela-historico .table-data:first-child {
    border-left: 0; }
  .tabela-historico .table-header {
    background-color: #e8e8e8;
    height: 30px; }
  .tabela-historico .table-header {
    border-left: 0px; }
    .tabela-historico .table-header .table-data {
      border-top: 2px solid #f5f5f5;
      text-align: center;
      width: 15%;
      font-weight: 600;
      font-size: 13px;
      text-transform: uppercase;
      color: #033F7B; }
  .tabela-historico .table-row {
    height: 50px;
    width: 100%;
    border-bottom: 2px solid #f5f5f5; }
  .tabela-historico .table-situacao {
    font-size: 13px;
    border: 2px solid #9FA1A2;
    font-weight: 400;
    padding: 5px;
    margin-top: 15px;
    display: inline-block; }

/* tabela de gabarito */
.tabela-gabarito {
  border: 5px solid rgba(0, 0, 0, 0.125); }
  .tabela-gabarito .avaliador-title-label {
    color: #228ec6;
    float: left;
    margin-left: 5px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 400; }
  .tabela-gabarito .avaliador-name-label {
    color: #807f7f;
    float: left;
    margin-left: 5px;
    font-size: 13px;
    font-weight: 400; }
  .tabela-gabarito table {
    width: 100%; }
  .tabela-gabarito .table-data {
    border-top: 2px solid #f5f5f5;
    border-left: 2px solid #f5f5f5;
    text-align: center;
    width: 15%;
    font-weight: 400;
    font-size: 13px; }
  .tabela-gabarito .table-data-border {
    border-top: 2px solid #f5f5f5;
    border-left: 2px solid #f5f5f5 !important;
    border-right: 2px solid #f5f5f5 !important;
    text-align: center;
    width: 15%;
    font-weight: 400;
    font-size: 13px; }
  .tabela-gabarito .table-data:first-child {
    border-left: 0; }
  .tabela-gabarito .table-header {
    background-color: #e8e8e8;
    height: 30px; }
  .tabela-gabarito .table-header {
    border-left: 0px; }
    .tabela-gabarito .table-header .table-data {
      border-top: 2px solid #f5f5f5;
      text-align: center;
      width: 15%;
      font-weight: 600;
      font-size: 13px;
      text-transform: uppercase;
      color: #033F7B; }
  .tabela-gabarito .table-row {
    height: 50px;
    width: 100%;
    border-bottom: 2px solid #f5f5f5; }
  .tabela-gabarito .table-situacao {
    font-size: 13px;
    border: 2px solid #9FA1A2;
    font-weight: 400;
    padding: 5px;
    margin-top: 15px;
    display: inline-block; }

.correcao-respostas {
  border: 5px solid rgba(0, 0, 0, 0.125);
  min-width: 263px;
  position: sticky !important;
  /* apenas chrome e webkit nightly */
  top: 0px !important; }
  .correcao-respostas .card {
    border: none !important; }
  .correcao-respostas .card-header-correcao, .correcao-respostas .card-header-ocorrencia {
    background: #60B2DD;
    padding: 5px 10px;
    color: white !important;
    font-weight: 600;
    font-size: 14px;
    text-align: left; }
  .correcao-respostas .card-header-correcao:HOVER, .correcao-respostas .card-header-correcao:FOCUS {
    background: #137DB1;
    cursor: pointer;
    outline: none; }
  .correcao-respostas .card-header-competencia {
    padding: 5px 5px;
    background: #f2f2f2;
    color: #004C99;
    font-weight: 600;
    font-size: 13px;
    text-align: right; }
  .correcao-respostas .card-header-competencia-left {
    padding: 5px 15px;
    background: #f2f2f2;
    color: #004C99;
    display: block;
    font-weight: 600;
    font-size: 13px; }
  .correcao-respostas .card-body div .input-group-custom {
    flex-direction: row;
    justify-content: flex-end; }
  .correcao-respostas .action-correcao {
    padding: 5px; }
    .correcao-respostas .action-correcao .input-nota-final {
      max-width: 230px;
      border: 3px solid #8AC1E7;
      padding-top: 3px;
      border-radius: 6px; }
      .correcao-respostas .action-correcao .input-nota-final label {
        font-weight: 700;
        color: #7B7A7A;
        font-size: 15px; }
      .correcao-respostas .action-correcao .input-nota-final span {
        color: #7B7A7A;
        font-weight: 500;
        font-size: 17px; }
  .correcao-respostas .border-bottom-card {
    border-radius: 0px !important;
    border-bottom: 5px solid rgba(0, 0, 0, 0.125) !important; }
  .correcao-respostas .close-card-header {
    border: none;
    cursor: pointer;
    background: none;
    color: #fff; }

.card-ocorrencias .card-body-title {
  font-weight: 600;
  color: #777;
  text-align: center;
  border: 2px solid #004C99;
  padding: 10px;
  border-radius: 5px; }

.card-ocorrencias .card-body-form p {
  font-weight: 600;
  color: #777;
  font-size: 14px; }
  .card-ocorrencias .card-body-form p span {
    color: #004C99; }

.card-ocorrencias .card-body-form label {
  color: #004C99;
  font-weight: 600;
  margin-top: 1rem; }

.card-ocorrencias .card-body-form input {
  color: #777; }

.card-ocorrencias .card-body-form select {
  color: #777;
  padding: 5px;
  border-radius: 5px;
  border: 2px solid #DEDEDE;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  font-size: 14px; }

.card-ocorrencias .card-body-form textarea {
  color: #777; }

.btn-fixed {
  position: fixed;
  right: 33%;
  top: 350px;
  z-index: 2;
  background: #004C99;
  border: none;
  width: 20px;
  height: 20px;
  border-radius: 30px;
  box-shadow: 0 0 3px #004C99;
  line-height: 1px;
  display: block; }
  .btn-fixed i {
    color: white;
    font-size: 12px; }
  .btn-fixed:focus {
    outline: none;
    box-shadow: none;
    border: none; }

._1x {
  right: 89.6% !important; }

._2x {
  right: 81.7% !important; }

._3x {
  right: 73.5% !important; }

._4x {
  right: 65.4% !important; }

._5x {
  right: 57.3% !important; }

._6x {
  right: 49.1% !important; }

._7x {
  right: 41% !important; }

._8x {
  right: 33% !important; }

._9x {
  right: 24.8% !important; }

._10x {
  right: 16.6% !important; }

._11x {
  right: 8.6% !important; }

._12x {
  right: 0.6% !important; }

.none_arrow {
  display: none !important; }

.btn-fixed:hover, .btn-fixed-competencia:hover {
  background: #00ACAC;
  cursor: pointer; }

@media (min-width: 400px) and (max-width: 1050px) {
  .correcao, .correcao-respostas {
    min-height: auto !important;
    margin-bottom: 50px; }
  .card-body {
    overflow: auto;
    margin-bottom: 0px !important; } }

@media (min-width: 910px) and (max-width: 1080px) {
  .container-radio {
    margin: 0px; }
  .action-correcao .action1 {
    padding-right: 2px; }
  .action-correcao .action2 {
    padding-left: 2px; }
  .action-correcao .bg-dark-default-color-correcao, .action-correcao .bg-default-color-correcao {
    font-size: 12px; } }

@media (max-width: 910px) {
  .btn-fixed, .btn-fixed-expand {
    display: none; }
  .col-redacao {
    max-width: 100% !important; }
  .col-competencias {
    max-width: 100% !important;
    flex: 100% !important; } }

.button-link {
  background: none;
  color: #60B2DD;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: block;
  margin: 5px;
  margin-left: 10px; }
  .button-link:hover {
    color: #137DB1; }

.indicador-descricao {
  font-size: 14px !important;
  padding: 1.1rem !important;
  font-weight: 600 !important;
  letter-spacing: 1px;
  text-align: justify; }

.desvios-input {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  margin-right: 20px; }
  .desvios-input label {
    margin-bottom: 0px !important;
    margin-right: 5px; }
  .desvios-input .input-group {
    width: 25% !important; }
  .desvios-input input {
    height: 30px !important; }

.login-bg {
  background: #FFFFFF; }
  .login-bg .box-form {
    margin-top: 12%;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 30px;
    border-bottom: 1px solid #004C99; }
  .login-bg .logo-login {
    max-width: 180px;
    text-align: center;
    margin: auto;
    float: none;
    display: block; }
  .login-bg .info-modulo span {
    font-size: 17px;
    font-weight: 700;
    text-transform: uppercase;
    color: #033F7B; }
  .login-bg .info-modulo .title-modulo {
    color: #8AC1E7; }
  .login-bg .alert {
    margin-top: 2%;
    font-size: 12px;
    text-align: left; }
    .login-bg .alert .close {
      padding: 0.5rem 1rem; }
  .login-bg .form-sign .group-input .text-input-form-state {
    position: relative; }
    .login-bg .form-sign .group-input .text-input-form-state i::before {
      position: absolute;
      left: 15px;
      bottom: 40px;
      color: #585858; }
  .login-bg .form-sign .group-input input {
    padding-left: 40px;
    height: 50px;
    font-size: 16px;
    border-radius: 10px;
    color: #898a8b;
    font-weight: 600;
    border-width: 2px;
    background: transparent; }
    .login-bg .form-sign .group-input input::placeholder {
      font-size: 12px; }
    .login-bg .form-sign .group-input input:focus {
      border-color: #DB6969;
      border-color: #9AC75C;
      border-color: #E59E00;
      border-color: #004C99;
      border-width: 2px;
      box-shadow: none;
      outline: 0 none; }
    .login-bg .form-sign .group-input input:focus.has-error {
      border-color: #DB6969;
      border-width: 2px;
      box-shadow: none;
      outline: 0 none; }
  .login-bg .form-sign .group-input input.has-error {
    border-color: #DB6969;
    border-width: 2px; }
  .login-bg .form-sign .group-input small {
    font-size: 12px;
    text-align: left; }
  .login-bg .form-sign .group-input small.has-error {
    color: #DB6969; }
  .login-bg .form-sign .action-login {
    display: flex;
    justify-content: space-between; }
  .login-bg .form-sign .custom-control-label {
    font-size: 15px !important;
    color: #585858;
    line-height: 25px;
    font-weight: 400; }
  .login-bg .recuperar-senha a {
    color: #004C99;
    border-bottom: 1px solid #033F7B;
    transition: all 0.3s linear;
    font-weight: 600; }
    .login-bg .recuperar-senha a:hover {
      text-decoration: none;
      color: #033F7B;
      transition: all 0.3s linear; }

.login-2 .box-form {
  border-bottom: none;
  margin-top: 40px;
  max-width: 640px; }

.login-2 .fa-lock {
  margin-right: 5px; }

.login-2 h2,
.login-2 h3 {
  font-weight: 600; }

.login-2 h2 {
  font-size: 24px;
  color: #033F7B; }

.login-2 h3 {
  font-size: 18px;
  color: #033F7B; }

.login-2 .logo-login {
  margin-bottom: 50px; }

.login-2 p {
  font-size: 14px;
  color: #888585; }

.login-2 .qr-code {
  border: 1px solid #888585;
  width: 225px;
  max-width: 100%;
  z-index: 9999; }

.login-2 .login-instructions {
  background: #F4F4F4;
  padding: 20px;
  margin-bottom: 10px;
  font-size: 14px; }
  .login-2 .login-instructions .download {
    border-left: 2px solid #FFFFFF;
    text-align: center !important; }
    .login-2 .login-instructions .download a {
      color: #004C99 !important;
      line-height: 110px;
      text-decoration: underline;
      font-weight: 600; }
  .login-2 .login-instructions h3 {
    font-size: 14px;
    color: #004C99;
    font-weight: 600; }
  .login-2 .login-instructions p span {
    color: #004C99;
    font-weight: 600; }
  .login-2 .login-instructions p a {
    color: #004C99;
    text-decoration: underline; }

.login-2 .form-sign {
  margin-top: 0; }

.login-2 label {
  display: inline; }

.text-spacing {
  letter-spacing: 1px; }

@media (max-width: 769px) {
  p {
    text-align: center; }
  h3,
  h2 {
    text-align: center;
    padding-top: 10px; }
  img {
    margin: auto;
    display: block; }
  .login-instructions {
    text-align: center; }
    .login-instructions .download {
      border-left: none !important;
      padding-bottom: 20px; }
      .login-instructions .download a {
        line-height: 10px !important; }
  .btn {
    margin-top: 0px !important; } }

.login-template {
  display: grid;
  height: 100vh;
  grid-template-areas: "content" "footer";
  grid-template-rows: 1fr; }

.text-area-border {
  border-radius: 10px;
  border: 2px solid #DEDEDE; }

.radio-button-group .radio-button-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%; }

.radio-button-group {
  display: inline-block;
  position: relative;
  padding-left: 15px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.radio-button-title {
  padding: 10px;
  border: 2px solid #033F7B;
  color: #004C99;
  border-radius: 5px;
  font-weight: 600; }

/* hover */
.radio-button-group:hover .radio-button-input ~ .radio-button-title {
  background-color: #033f7b;
  color: white; }

/* checked */
.radio-button-group .radio-button-input:checked ~ .radio-button-title {
  background-color: #033f7b;
  color: white; }

.radio-button-title:after {
  content: "";
  position: absolute;
  display: none; }

.radio-button-group .radio-button-input:checked ~ .radio-button-title:after {
  display: block; }

.radio-button-group .radio-button-title:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.countdown-correcao {
  font-size: 14px;
  color: #888585;
  font-weight: 400;
  text-align: center; }
  .countdown-correcao .countdown-style {
    font-weight: 700; }

.panel-default {
  border-radius: 5px 5px 0px 0px;
  border: 1px solid #f2f2f2;
  margin: 10px;
  padding-right: 0px !important;
  padding-left: 0px !important; }
  .panel-default .panel-title {
    border-radius: 5px 5px 0px 0px;
    min-height: 60px;
    background: #004C99;
    padding: 15px 30px;
    margin: 0px;
    color: #F4F4F4;
    border: none; }
    .panel-default .panel-title h2 {
      font-size: 19px;
      font-weight: 400;
      margin: 0;
      text-align: left; }
  .panel-default .panel-body {
    padding: 15px 30px;
    background: #FFFFFF;
    min-height: 50px;
    overflow: auto; }
    .panel-default .panel-body ::-webkit-scrollbar {
      -webkit-appearance: none; }
    .panel-default .panel-body ::-webkit-scrollbar {
      width: 9px; }
    .panel-default .panel-body ::-webkit-scrollbar-track {
      -webkit-border-radius: 5px;
      border-radius: 5px;
      background: rgba(0, 0, 0, 0.1); }
    .panel-default .panel-body ::-webkit-scrollbar-thumb {
      -webkit-border-radius: 5px;
      border-radius: 5px;
      background: rgba(0, 0, 0, 0.2); }
    .panel-default .panel-body ::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.4); }
    .panel-default .panel-body ::-webkit-scrollbar-thumb:window-inactive {
      background: rgba(0, 0, 0, 0.5); }
    .panel-default .panel-body a {
      font-size: 15px;
      color: #004C99; }
      .panel-default .panel-body a:hover {
        text-decoration: none; }
      .panel-default .panel-body a span {
        color: #888585;
        font-weight: 100; }
      .panel-default .panel-body a p, .panel-default .panel-body a p > span {
        padding-top: 10px;
        color: #888585;
        font-weight: 400; }
    .panel-default .panel-body .ocorrencia {
      padding: 10px 5px;
      border: 1px solid #f2f2f2;
      margin-bottom: 10px; }
      .panel-default .panel-body .ocorrencia p {
        margin: 0px; }
        .panel-default .panel-body .ocorrencia p:nth-child(1) {
          font-size: 28px;
          font-weight: 600;
          color: #8AC1E7; }
        .panel-default .panel-body .ocorrencia p:nth-child(2) {
          font-size: 13px;
          color: #888585;
          text-transform: uppercase;
          font-weight: 600; }
          .panel-default .panel-body .ocorrencia p:nth-child(2) span {
            font-weight: 100; }
      .panel-default .panel-body .ocorrencia .btn {
        padding: 5px !important;
        margin: 2px;
        width: 100%; }
      .panel-default .panel-body .ocorrencia .btn-primary {
        background: #004C99 !important; }
  .panel-default .link-detalhes a {
    color: #ffff !important; }
  .panel-default .btn-primary {
    border: none;
    text-transform: uppercase !important; }
  .panel-default .panel-body-aviso {
    margin-bottom: 10px;
    color: #333333;
    font-size: 14px; }

.panel-darkblue .panel-title {
  background: #033F7B !important;
  vertical-align: middle; }

.panel-darkblue .btn-primary {
  background: #004C99 !important;
  padding: 10px !important; }

.panel-darkblue .panel-body--grafico {
  background: #FFFFFF;
  padding: 10px;
  padding-bottom: 550px;
  padding-top: 20px; }
  .panel-darkblue .panel-body--grafico svg {
    height: 400px !important; }
    .panel-darkblue .panel-body--grafico svg .d3plus-viz-legend {
      transform: translate(36px, 20px) !important; }
  .panel-darkblue .panel-body--grafico .d3plus-Bar-text .d3plus-textBox text {
    fill: white !important;
    margin-top: 10px; }
  .panel-darkblue .panel-body--grafico .d3plus-textBox text {
    font-size: 12px !important;
    fill: #888585;
    font-family: "Inter" !important; }
  .panel-darkblue .panel-body--grafico .d3plus-Bar-hover text {
    fill: white; }
  .panel-darkblue .panel-body--grafico .legend-graph ul {
    display: inline-flex; }
    .panel-darkblue .panel-body--grafico .legend-graph ul li {
      margin-left: 10px; }
  .panel-darkblue .panel-body--grafico .shape-legend {
    width: 20px;
    height: 20px;
    border-radius: 30px; }
    .panel-darkblue .panel-body--grafico .shape-legend:first-child {
      background: #389CBD; }
  .panel-darkblue .panel-body--grafico .label-legend {
    font-size: 13px;
    color: #888585; }
  .panel-darkblue .panel-body--grafico .painel-info-atualizacao p {
    padding-top: 20px;
    border-top: 1px solid #ccc;
    color: #888585;
    font-size: 13px;
    margin: 10px 20px;
    margin-left: 30px; }
  .panel-darkblue .panel-body--grafico .box-total-graph {
    margin-top: 0px;
    border: 2px solid #004C99;
    width: 200px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px; }
    .panel-darkblue .panel-body--grafico .box-total-graph .label-total {
      color: #033F7B;
      font-weight: 400; }
    .panel-darkblue .panel-body--grafico .box-total-graph span {
      font-size: 14px; }

.box-tooltip:parent {
  box-shadow: 5px 10px #888888 !important;
  color: red;
  background: red; }

.default-background {
  padding: 20px;
  background-color: #f8f8f8; }

.lista-num-correcoes {
  display: flex;
  display: none;
  justify-content: space-between; }
  .lista-num-correcoes .correcao-feita {
    background: #004C99;
    color: white;
    border: 1px solid #fff; }
  .lista-num-correcoes td {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    min-width: 32px;
    margin-left: 4px;
    border-right: 1px solid #ccc;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center; }
    .lista-num-correcoes td:first-child {
      border-left: 1px solid #ccc; }
    .lista-num-correcoes td:hover {
      opacity: .8; }

@media (min-width: 1200px) {
  .lista-num-correcoes {
    display: block !important; } }

@media (max-width: 1199px) {
  .text-num-correcoes-feita {
    display: block !important; } }

.d3plus-tooltip {
  box-shadow: 5px 10px 10px #88888820;
  border: 1px solid #eeeeee !important; }

.text-num-correcoes-feita article {
  width: 180px;
  height: 180px;
  background: #033F7B;
  border-radius: 180px;
  color: white;
  padding-top: 40px;
  border: 3px solid #ccc; }
  .text-num-correcoes-feita article .destaque {
    font-size: 30px;
    font-weight: 800;
    margin-right: 5px;
    color: #8ac1e7; }
  .text-num-correcoes-feita article .exercicio-completo-message {
    padding: 5px;
    background-color: green; }

.seletor .input-group-custom {
  margin-top: 30px !important;
  display: block !important; }
  .seletor .input-group-custom .container-radio {
    margin-bottom: 20px !important; }

.list-buttons {
  list-style: none;
  display: inline-flex !important; }
  .list-buttons .btn-primary {
    background: #004C99;
    min-height: 100%;
    min-height: 40px;
    max-height: 40px; }
  .list-buttons li:nth-last-child(1) {
    position: absolute;
    right: 0;
    margin-right: 20px; }
  @media (max-width: 990px) {
    .list-buttons {
      display: block !important;
      text-align: center; }
      .list-buttons li {
        margin: 30px 0px;
        padding: 0; }
        .list-buttons li a {
          margin: 0px; }
        .list-buttons li:nth-last-child(1) {
          position: relative;
          margin-right: 0px !important; }
          .list-buttons li:nth-last-child(1) .btn-primary {
            float: none !important; } }

.card-correcoes .card-header {
  border-bottom: 2px solid #FFFFFF !important;
  background: #f2f2f2 !important;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .card-correcoes .card-header .text-subtitle {
    letter-spacing: 1px;
    font-size: 15px !important;
    font-weight: 600; }
    @media (max-width: 990px) {
      .card-correcoes .card-header .text-subtitle {
        text-align: center !important; } }
  .card-correcoes .card-header .text-available {
    color: #033F7B !important;
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 14px; }

.card-correcoes .card {
  padding-top: 10px;
  padding-bottom: 10px;
  border: none !important;
  background: #f2f2f2 !important;
  margin-top: 30px; }

.modal-supervisor .modal-title-supervisor {
  margin-bottom: 25px;
  padding-bottom: 5px;
  border-bottom: 1px solid #eee; }
  .modal-supervisor .modal-title-supervisor h3 {
    font-size: 26px;
    color: #033F7B;
    font-weight: 300; }

.modal-supervisor .modal-body-supervisor .modal-content-supervisor {
  background: #F8F7F7;
  padding: 60px 30px; }
  .modal-supervisor .modal-body-supervisor .modal-content-supervisor-title {
    color: #033F7B;
    font-weight: 700;
    font-size: 16px; }
  .modal-supervisor .modal-body-supervisor .modal-content-supervisor .modal-line-supervisor {
    border: 1px solid #fff; }
  .modal-supervisor .modal-body-supervisor .modal-content-supervisor-body .button-view {
    float: right; }
    .modal-supervisor .modal-body-supervisor .modal-content-supervisor-body .button-view .btn-verificar {
      height: 45px;
      background: #218EC6;
      font-weight: 300;
      color: #fff;
      border: none;
      border-radius: 5px;
      padding: 0 30px;
      margin-right: 30px; }
      .modal-supervisor .modal-body-supervisor .modal-content-supervisor-body .button-view .btn-verificar:hover {
        cursor: pointer; }
    .modal-supervisor .modal-body-supervisor .modal-content-supervisor-body .button-view .btn-confirmar {
      height: 45px;
      background: #5cb85c;
      font-weight: 300;
      color: #fff;
      border: none;
      border-radius: 5px;
      padding: 0 30px; }
      .modal-supervisor .modal-body-supervisor .modal-content-supervisor-body .button-view .btn-confirmar:hover {
        cursor: pointer; }
    .modal-supervisor .modal-body-supervisor .modal-content-supervisor-body .button-view .btn-confirmar-disabled {
      height: 45px;
      background: #d0d0d0;
      font-weight: 300;
      color: #fff;
      border: none;
      border-radius: 5px;
      padding: 0 30px; }
      .modal-supervisor .modal-body-supervisor .modal-content-supervisor-body .button-view .btn-confirmar-disabled:hover {
        cursor: not-allowed; }
  .modal-supervisor .modal-body-supervisor .modal-content-supervisor-body form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end; }
    .modal-supervisor .modal-body-supervisor .modal-content-supervisor-body form .text-input-form-state {
      display: flex;
      align-items: center;
      margin-bottom: 1rem; }
      .modal-supervisor .modal-body-supervisor .modal-content-supervisor-body form .text-input-form-state label {
        color: #8F8F8F;
        font-weight: 600;
        margin-right: 20px;
        margin-bottom: 0 !important; }
      .modal-supervisor .modal-body-supervisor .modal-content-supervisor-body form .text-input-form-state input {
        height: 35px;
        width: 200px;
        border: 1px solid #E2E2E2; }
    .modal-supervisor .modal-body-supervisor .modal-content-supervisor-body form .input-line {
      display: flex;
      align-items: center; }
      .modal-supervisor .modal-body-supervisor .modal-content-supervisor-body form .input-line .nome-auxiliar {
        color: #313131;
        align-items: center;
        margin-left: 10px;
        font-weight: 600; }

.tag-list {
  display: flex; }
  .tag-list .tag-status-correcoes {
    width: 50% !important; }
    .tag-list .tag-status-correcoes:last-child {
      margin-right: 0; }

.tag-status-correcoes {
  width: 270px;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  font-weight: 600;
  border: 1px solid #d3d3d3;
  text-transform: uppercase;
  margin-right: 20px;
  line-height: 1.5;
  letter-spacing: 1px;
  margin-bottom: 10px; }

.tag-title {
  margin-bottom: 15px;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 14px;
  display: block; }

.tag-status-correcoes-list {
  display: flex;
  justify-content: space-between; }
  .tag-status-correcoes-list li {
    font-size: 12px;
    font-weight: 200;
    padding: 5px; }
    .tag-status-correcoes-list li span {
      font-weight: 600; }

@media (max-width: 768px) {
  .tag-list {
    display: block; }
    .tag-list .tag-status-correcoes {
      display: block; }
      .tag-list .tag-status-correcoes .tag-status-correcoes-list {
        display: block; }
  .card-correcoes .card-header {
    margin-bottom: 0px; }
  .card-correcoes .card-body {
    padding-top: 5px !important; }
  .list-buttons .btn-primary {
    width: 100%; } }

@media (max-width: 991px) {
  .card-correcoes .card-body {
    padding-top: 5px !important; }
    .card-correcoes .card-body .tag-status-correcoes {
      width: 100% !important; }
    .card-correcoes .card-body .btn-primary {
      width: 100% !important; } }

@media (max-width: 1200px) {
  .card-correcoes .card-body {
    padding-top: 25px !important; }
    .card-correcoes .card-body .tag-status-correcoes {
      width: 220px;
      font-size: 11px;
      margin-right: 5px; }
    .card-correcoes .card-body .btn-primary {
      width: 110px; } }

.card-correcoes.modelo-supervisor {
  text-align: center; }
  .card-correcoes.modelo-supervisor .title-unico-info-correcoes {
    height: 50px;
    font-size: 14px;
    font-weight: 600; }
  .card-correcoes.modelo-supervisor .title-info-correcoes {
    height: 25px;
    font-size: 14px;
    font-weight: 600; }
  .card-correcoes.modelo-supervisor .title-info-correcoes.sub-title {
    height: 25px;
    font-size: 13px;
    font-weight: 600; }
  .card-correcoes.modelo-supervisor .value-info-correcoes {
    height: 25px;
    font-size: 13px;
    font-weight: 600; }

.body-dados img {
  max-width: 100%; }

.body-dados h3 {
  color: #033F7B;
  font-size: 15px;
  letter-spacing: 0.5px;
  margin: 20px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #004C99; }
  .body-dados h3 i {
    margin-right: 10px;
    font-size: 20px !important; }

.body-dados .body-dados--habilidades p {
  color: #888585; }
  .body-dados .body-dados--habilidades p:nth-child(1) {
    margin-top: 30px;
    text-transform: uppercase; }
  .body-dados .body-dados--habilidades p:nth-child(2) {
    margin-bottom: 6px; }
  .body-dados .body-dados--habilidades p:nth-child(3) {
    color: #4A4747; }

.body-dados .text-name {
  color: #888585;
  font-size: 25px; }
  .body-dados .text-name .fas {
    margin: 0 15px;
    font-size: 20px !important; }
  .body-dados .text-name span:nth-child(2) {
    font-size: 15px;
    vertical-align: middle; }

.body-dados .text-occupation {
  color: #004C99;
  font-size: 14px !important;
  font-weight: 600;
  letter-spacing: 0.5px; }

.body-dados .btn {
  margin-top: 30px;
  padding: 18px 25px !important;
  letter-spacing: 1.5px;
  font-size: 15px !important; }

.body-dados .btn-disabled {
  margin-top: 20px;
  border: 1px solid #004C99 !important;
  background: #FFFFFF;
  cursor: default !important; }
  .body-dados .btn-disabled span {
    text-transform: uppercase;
    letter-spacing: 0.5px; }
    .body-dados .btn-disabled span:nth-child(2) {
      font-weight: 800 !important;
      letter-spacing: 1px; }

.body-dados .body-dados--contato {
  color: #888585; }
  .body-dados .body-dados--contato p {
    font-weight: 600;
    letter-spacing: 1px; }
    .body-dados .body-dados--contato p row {
      margin-top: 30px;
      font-size: 13px;
      margin-bottom: 20px; }
    .body-dados .body-dados--contato p span {
      font-weight: 400; }
  .body-dados .body-dados--contato .text-default-blue {
    color: #8AC1E7;
    font-weight: 600; }

.btn-more {
  color: white;
  background: #004C99;
  padding: 5px 8px !important; }
  .btn-more:hover {
    color: white !important;
    background: #033F7B; }

.btn-more-eye {
  color: #4b4b4b;
  background: transparent !important;
  padding: 5px 8px !important;
  border: 1px solid #4b4b4b !important; }

.nav-tabs {
  border: none !important; }

.nav-link {
  background: #f2f2f2 !important;
  color: #888585 !important;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600; }

.nav-tabs .nav-link.active {
  background: #004C99 !important;
  color: #fff !important;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  box-shadow: 0 0 15px #f2f2f2; }

.ReactTable {
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  color: #888585; }

.rt-thead.-header {
  box-shadow: none !important;
  border-bottom: 2px solid #ddd; }

.rt-th {
  box-shadow: none !important;
  padding: 15px !important; }

.rt-tr-group:nth-child(2n) {
  background: #eee; }

.rt-resizable-header-content {
  color: #004C99 !important;
  font-weight: 600 !important; }

.rt-tr {
  align-items: center; }

.-pagination {
  display: flex;
  justify-content: center !important;
  box-shadow: none !important; }

.-pagination .-previous,
.-pagination .-next {
  width: 200px !important;
  max-width: 200px !important; }

.-pagination .-previous .-btn,
.-pagination .-next .-btn {
  color: #004C99 !important;
  font-weight: 600;
  max-width: 150px !important;
  width: 100%;
  opacity: 1 !important; }

.-pagination .-center {
  flex: none !important; }

.-pageJump input {
  color: #004C99 !important;
  font-weight: 600 !important; }

.form-style {
  font-size: 14px;
  color: #888585;
  display: grid; }
  .form-style input {
    border-left: 10px solid #ddd;
    padding: 5px; }

.line {
  border: 1px solid #ddd;
  width: 96%;
  margin: 20px auto; }

.tag-status-ocorrencias {
  text-align: center;
  padding: 7px;
  border: 2px solid #eee;
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
  margin: 2px;
  cursor: default; }

.modal-dados-avaliador .avaliador-img {
  text-align: center;
  background: #f2f2f2; }
  .modal-dados-avaliador .avaliador-img img {
    max-width: 200px; }

.modal-dados-avaliador .desempenho-section-title {
  color: #033F7B;
  font-weight: 600;
  font-size: 14px; }
  .modal-dados-avaliador .desempenho-section-title .line-down {
    border-bottom: 1px solid #004C99; }

.modal-dados-avaliador .avaliador-inf {
  font-size: 20px;
  color: #7D7E7E;
  font-weight: 600; }
  .modal-dados-avaliador .avaliador-inf span {
    text-transform: capitalize; }

.modal-dados-avaliador .modal-body {
  max-height: 500px;
  overflow: auto; }

.modal-dados-avaliador .form-state-name {
  display: none !important; }

.modulo9 .rodal-dialog {
  width: 80% !important;
  min-height: 540px !important;
  position: absolute !important;
  margin-top: 150px; }

.form-check {
  padding-left: 0 !important; }
  .form-check input {
    margin-left: 5px; }

.modal-header {
  border-bottom: none !important; }
  .modal-header .text-title {
    padding-top: 0px !important; }

.avaliador-font-sm {
  font-size: 12px;
  font-weight: 300;
  text-transform: none !important;
  color: #7D7E7E;
  letter-spacing: 1px; }
  .avaliador-font-sm strong {
    font-weight: 600; }

.modal-form .avaliador-font-sm {
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0; }

.btn-tag {
  border: 1px solid #004C99;
  width: 300px;
  text-align: center;
  padding: 12px 0;
  border-radius: 5px;
  margin-top: 1.5rem;
  font-size: 14px !important; }
  .btn-tag .avaliador-font-sm {
    font-size: 14px; }

.data-section {
  margin-top: 2rem;
  text-align: left !important; }
  .data-section h5 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    color: #033F7B;
    padding-bottom: 5px;
    border-bottom: 1px solid #004C99; }
  .data-section p {
    text-transform: capitalize; }

.text-lw {
  text-transform: lowercase; }

.modal-btn-group button {
  color: white;
  font-size: 14px;
  padding: 10px 25px; }

.select-situacao select {
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase; }
  .select-situacao select:focus {
    box-shadow: none;
    border: 1px solid #888585; }

.select-situacao label {
  display: none !important; }

@media (max-width: 678px) {
  .modal-dados-avaliador .rodal-dialog {
    width: 90% !important;
    min-height: 700px !important;
    position: absolute !important;
    margin-top: 100px; }
  .avaliador-font-sm {
    letter-spacing: 0px !important; }
  .btn-tag {
    max-width: 250px; } }

.table-lista-avaliadores .rt-th {
  color: #033F7B;
  font-weight: 600;
  text-align: left; }

.table-lista-avaliadores .rt-td {
  text-align: center; }

.carometro {
  background: #f8f8f8;
  border-radius: 4px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: 15px !important;
  margin-bottom: 20px !important; }
  .carometro p {
    font-size: 13px;
    color: #888585;
    margin-bottom: 5px;
    letter-spacing: 0.8px; }
    .carometro p span:nth-child(1) {
      text-transform: uppercase;
      color: #004C99;
      font-weight: 600;
      letter-spacing: 1px; }
    .carometro p span .fas {
      color: #E59E00; }
      @media (max-width: 790px) {
        .carometro p span .fas {
          font-size: 9px; } }
    .carometro p .pontos {
      margin-right: 7px !important; }
  .carometro .updated {
    font-size: 10px;
    color: #9FA1A2;
    font-style: italic; }
  .carometro img {
    max-width: 100%;
    border-radius: 4px 0 0 4px; }
    @media (max-width: 790px) {
      .carometro img {
        border-radius: 4px 4px 0 0 !important; } }
  .carometro .imagen-avaliador {
    padding-left: 0px !important;
    padding-right: 0px !important; }
  .carometro .info-avaliador {
    padding-top: 20px; }

.filtro {
  margin: 10px 0px; }
  .filtro select {
    text-transform: none !important; }

.total p {
  font-size: 14px;
  text-align: center;
  color: #4A4747; }
  .total p span {
    font-weight: 600; }

.tooltip-table {
  font-size: 12px !important;
  color: #004C99; }

.alerta {
  color: #DD7C60; }

.legendas li {
  color: #4A4747;
  display: inline;
  padding: 0 10px; }
  .legendas li:nth-child(1) {
    font-weight: 600; }
  .legendas li:nth-child(n+2) {
    font-weight: 100; }

.legendas .fas {
  margin-right: 5px; }

.legendas .rosa {
  color: #F0D9D2; }

.legendas .amarelho {
  color: #F6EEC3; }

.table-notas {
  text-align: center;
  background: #F4F4F4; }
  .table-notas p {
    margin-bottom: 0 !important;
    padding: 0 !important; }
  .table-notas .rt-thead {
    background: #F4F4F4 !important;
    border-bottom: 2px solid #FFFFFF !important; }
  .table-notas .-headerGroups .rt-th {
    font-size: 14px !important;
    color: #033F7B !important;
    font-weight: 600;
    text-transform: uppercase;
    border-right: 2px solid #FFFFFF !important;
    padding: 10px; }
  .table-notas .-header {
    color: #033F7B !important;
    font-size: 15px !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
    .table-notas .-header .rt-th {
      padding: 15px 0; }
      .table-notas .-header .rt-th:nth-child(6) {
        border-right: 2px solid #FFFFFF !important; }
      .table-notas .-header .rt-th:nth-child(10) {
        border-right: 2px solid #FFFFFF !important; }
      .table-notas .-header .rt-th:nth-child(15) {
        border-right: 2px solid #FFFFFF !important; }
      .table-notas .-header .rt-th:nth-child(21) {
        border-right: 2px solid #FFFFFF !important; }
      .table-notas .-header .rt-th:nth-child(27) {
        border-right: 2px solid #FFFFFF !important; }
    .table-notas .-header .rt-th .rt-resizable-header-content {
      color: #033F7B !important;
      font-weight: 400 !important; }
      .table-notas .-header .rt-th .rt-resizable-header-content i {
        position: absolute;
        right: 2px;
        top: 22px; }
    .table-notas .-header .rt-th:nth-child(n+7) {
      font-size: 13px; }
      .table-notas .-header .rt-th:nth-child(n+7) .rt-resizable-header-content {
        background: #FFFFFF !important;
        margin: auto;
        max-width: 20px;
        color: #888585 !important;
        font-weight: 600;
        padding: 2px;
        border-radius: 4px;
        border: 1px solid #888585 !important; }
      .table-notas .-header .rt-th:nth-child(n+7):nth-child(28) .rt-resizable-header-content {
        min-width: 80% !important; }
  .table-notas .rt-tr-group {
    border-bottom: none !important; }
    .table-notas .rt-tr-group .F6EEC3:hover {
      background: #efe3a6 !important; }
    .table-notas .rt-tr-group .F0D9D2:hover {
      background: #f5cabd !important; }
    .table-notas .rt-tr-group .rt-td {
      font-size: 13px !important;
      padding: 10px 0 !important;
      color: #484646 !important;
      border-right: 1px solid rgba(0, 0, 0, 0.05) !important; }
      .table-notas .rt-tr-group .rt-td:nth-child(1) {
        background: rgba(0, 0, 0, 0.1);
        border-right: 2px solid #FFFFFF;
        border-bottom: none !important;
        border-top: none !important; }
      .table-notas .rt-tr-group .rt-td:nth-child(6) {
        border-right: 2px solid #FFFFFF !important; }
      .table-notas .rt-tr-group .rt-td:nth-child(12) {
        border-right: 2px solid #FFFFFF !important; }
      .table-notas .rt-tr-group .rt-td:nth-child(17) {
        border-right: 2px solid #FFFFFF !important; }
      .table-notas .rt-tr-group .rt-td:nth-child(23) {
        border-right: 2px solid #FFFFFF !important; }
      .table-notas .rt-tr-group .rt-td:nth-child(29) {
        border-right: 2px solid #FFFFFF !important; }
  .table-notas .rt-tfoot .rt-td {
    background: #004C99 !important;
    color: #FFFFFF !important;
    padding: 0px 0px 0px 0px; }
    .table-notas .rt-tfoot .rt-td p {
      padding: 10px 0px 10px 0px; }
    .table-notas .rt-tfoot .rt-td table {
      margin: 0;
      padding: 0;
      max-width: 100%;
      display: block; }
      .table-notas .rt-tfoot .rt-td table tbody {
        max-width: 100%;
        display: block; }
      .table-notas .rt-tfoot .rt-td table td {
        max-width: 100%;
        display: block; }
      .table-notas .rt-tfoot .rt-td table tr {
        padding: 10px 0px 10px 0px;
        display: block; }
        .table-notas .rt-tfoot .rt-td table tr:nth-child(2) {
          background: #033F7B; }
    .table-notas .rt-tfoot .rt-td:nth-child(6) {
      border-right: 2px solid #FFFFFF !important; }
    .table-notas .rt-tfoot .rt-td:nth-child(12) {
      border-right: 2px solid #FFFFFF !important; }
    .table-notas .rt-tfoot .rt-td:nth-child(17) {
      border-right: 2px solid #FFFFFF !important; }
    .table-notas .rt-tfoot .rt-td:nth-child(23) {
      border-right: 2px solid #FFFFFF !important; }
    .table-notas .rt-tfoot .rt-td:nth-child(29) {
      border-right: 2px solid #FFFFFF !important; }
  .table-notas .reciclagem {
    background: #F6EEC3 !important; }
  .table-notas .right-arrow {
    position: absolute;
    right: 5px; }

.table-situacao .rt-thead {
  background: #F4F4F4;
  border-bottom: 2px solid #FFFFFF !important; }
  .table-situacao .rt-thead .rt-th {
    border-right: 2px solid #FFFFFF !important;
    font-size: 14px;
    padding: 15px 0px !important; }
    .table-situacao .rt-thead .rt-th .rt-resizable-header-content {
      color: #9FA1A2 !important;
      font-weight: 400 !important; }

.table-situacao .rt-tbody .rt-tr-group {
  background: #F4F4F4;
  border-bottom: 0px !important; }
  .table-situacao .rt-tbody .rt-tr-group:nth-child(even) {
    background: #E9EEF0; }

.table-situacao .rt-tbody .rt-td {
  font-weight: 400 !important;
  color: #6d6969; }
  .table-situacao .rt-tbody .rt-td:nth-child(1) {
    background: rgba(0, 0, 0, 0.1); }
  .table-situacao .rt-tbody .rt-td:nth-child(1n) {
    border-right: 2px solid #FFFFFF !important; }

.table-situacao .rt-tfoot .rt-td:nth-child(1n) {
  border-right: 2px solid #FFFFFF !important; }

.box-central-avisos .panel-default {
  background: #ffffff;
  border: none;
  width: 100%;
  border: 1px solid #f2f2f2;
  padding-left: 10px;
  padding-right: 10px; }
  .box-central-avisos .panel-default .panel-title {
    background: #8AC1E7 !important;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 35px;
    padding-right: 35px; }
    .box-central-avisos .panel-default .panel-title h2 {
      line-height: 40px;
      padding: 0px; }
  .box-central-avisos .panel-default .panel-body-avisos {
    background: #f7f7f7;
    margin-bottom: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 35px;
    padding-right: 35px;
    margin-left: 15px;
    margin-right: 15px; }
    .box-central-avisos .panel-default .panel-body-avisos .header-card-avisos {
      margin-top: 10px; }
    .box-central-avisos .panel-default .panel-body-avisos h3 {
      font-size: 15px;
      font-weight: 600;
      text-transform: uppercase;
      color: #004C99;
      letter-spacing: 1px;
      margin-top: 0px; }
    .box-central-avisos .panel-default .panel-body-avisos .lista-avaliadores-avisos {
      font-size: 12px;
      color: #888585;
      text-align: left; }
      .box-central-avisos .panel-default .panel-body-avisos .lista-avaliadores-avisos i {
        color: #033F7B; }
      .box-central-avisos .panel-default .panel-body-avisos .lista-avaliadores-avisos a {
        color: #033F7B;
        font-weight: 600;
        margin-left: 10px;
        text-transform: uppercase;
        font-size: 12px; }
    .box-central-avisos .panel-default .panel-body-avisos .content-aviso .msg-aviso {
      margin-top: 10px;
      padding-bottom: 10px; }
      .box-central-avisos .panel-default .panel-body-avisos .content-aviso .msg-aviso p {
        font-size: 15px;
        color: #888585;
        font-weight: 300;
        text-align: left; }
    .box-central-avisos .panel-default .panel-body-avisos .content-aviso .hidden {
      max-height: 80px;
      overflow: hidden;
      transition: all .1s ease; }
    .box-central-avisos .panel-default .panel-body-avisos .content-aviso .show {
      max-height: 100%;
      overflow: hidden;
      transition: all .1s ease; }
    .box-central-avisos .panel-default .panel-body-avisos .content-aviso .btn-link-ver-mais {
      background: transparent;
      color: #8AC1E7;
      font-size: 14px;
      margin-top: 0px; }
      .box-central-avisos .panel-default .panel-body-avisos .content-aviso .btn-link-ver-mais:hover {
        background: #033F7B; }
      .box-central-avisos .panel-default .panel-body-avisos .content-aviso .btn-link-ver-mais:focus {
        outline: none;
        box-shadow: none; }
    .box-central-avisos .panel-default .panel-body-avisos .id-comunicado-aviso {
      color: #888585;
      font-weight: 300;
      font-size: 14px;
      margin-top: 20px; }
    .box-central-avisos .panel-default .panel-body-avisos .info-central-avisos {
      text-align: center;
      background: #fff;
      font-size: 12px;
      padding: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      border-radius: 6px;
      color: #888585;
      border: 1px solid #ccc; }
      .box-central-avisos .panel-default .panel-body-avisos .info-central-avisos span {
        font-weight: 600; }

.novo-aviso .titulo-aviso label, .novo-aviso .mensagem-aviso label, .novo-aviso .periodo-ativacao label {
  color: #939696;
  font-weight: 600; }

.novo-aviso label {
  color: #939696;
  font-weight: 400;
  font-size: 14px; }

.novo-aviso input, .novo-aviso select {
  border-radius: 5px;
  border: 1px solid #ddd;
  border-left: 8px solid #ddd;
  height: 40px;
  padding-right: 20px; }
  .novo-aviso input:focus, .novo-aviso select:focus {
    outline: none;
    box-shadow: none; }

.novo-aviso .bg-dark-default-color, .novo-aviso .bg-default-color {
  width: 200px; }

.novo-aviso .rdw-editor-main {
  border: 1px solid #dddddd;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 6px;
  height: 150px;
  overflow: auto; }

.aviso-text {
  color: #958E8E;
  font-size: 16px; }

.aviso-text p {
  margin-bottom: 0; }

.aviso-title {
  color: #218EC6;
  font-size: 18px;
  font-weight: 600; }

.aviso-date {
  color: #958E8E;
  font-weight: bold; }

.navegador-incompativel {
  margin-left: auto;
  margin-right: auto;
  max-width: 500px; }

.logo {
  margin-top: 50px;
  margin-bottom: 50px; }
  .logo img {
    max-width: 180px; }

.exclamacao {
  font-size: 60px;
  color: #DB6969;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px; }

.incompativel-texto {
  text-align: center;
  font-size: 15px !important;
  border: none;
  padding-top: 25px;
  color: #888585;
  border-top: 1px solid #D8DBDD;
  border-bottom: 1px solid #D8DBDD;
  margin-top: 25px;
  margin-bottom: 30px; }
  .incompativel-texto p {
    max-width: 80%;
    margin: auto !important;
    float: none !important;
    display: block !important; }

.navegadores-compativeis {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px; }

.download-link {
  display: flex;
  flex-direction: column;
  align-items: center; }

.navegador {
  position: relative; }
  .navegador a {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #004C99; }
    .navegador a span {
      font-size: 11px;
      margin-top: 5px; }

.legenda {
  text-align: center;
  margin-top: 5px;
  color: #888585; }

.correcoes-info {
  font-size: 14px;
  color: #033F7B;
  text-transform: lowercase;
  letter-spacing: 1px;
  font-weight: 600; }
  .correcoes-info i {
    margin-right: 5px; }

.no-btn li:nth-last-child(1) {
  position: relative !important; }

.not-found {
  display: block;
  margin-top: 120px; }
  .not-found h1 {
    text-align: center;
    color: #8AC1E7;
    font-size: 96px;
    font-weight: 900;
    letter-spacing: 20px;
    font-family: 'Open Sans'; }
  .not-found h2 {
    color: #033F7B;
    font-size: 17px;
    font-weight: 500; }
  .not-found p {
    margin-top: 70px;
    color: #033F7B;
    font-size: 14px; }
  .not-found a {
    color: #033F7B; }
  .not-found figure {
    height: 246px;
    width: 175px;
    margin: auto; }

.tabela-notas-global .-headerGroups .rt-th {
  font-size: 14px !important;
  color: #033F7B !important;
  font-weight: 600;
  text-transform: uppercase;
  border-right: 2px solid #FFFFFF !important;
  padding: 10px; }

.tabela-notas-global .rt-th .rt-resizable-header-content {
  color: #033F7B !important;
  font-weight: 400 !important; }

.tabela-notas-global .rt-th:nth-child(n+2) {
  font-size: 13px; }
  .tabela-notas-global .rt-th:nth-child(n+2) .rt-resizable-header-content {
    background: #FFFFFF !important;
    margin: auto;
    max-width: 20px;
    color: #888585 !important;
    font-weight: 600;
    padding: 2px;
    border-radius: 4px;
    border: 1px solid #888585 !important; }
  .tabela-notas-global .rt-th:nth-child(n+2):nth-child(25) .rt-resizable-header-content {
    min-width: 100% !important; }

.tabela-notas-global .rt-thead {
  background: #F4F4F4 !important;
  border-bottom: 2px solid #FFFFFF !important; }

.tabela-notas-global .-header {
  color: #033F7B !important;
  font-size: 15px !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }
  .tabela-notas-global .-header .rt-th {
    padding: 15px 0; }
    .tabela-notas-global .-header .rt-th:nth-child(1) {
      border-right: 2px solid #FFFFFF !important; }
    .tabela-notas-global .-header .rt-th:nth-child(7) {
      border-right: 2px solid #FFFFFF !important; }
    .tabela-notas-global .-header .rt-th:nth-child(12) {
      border-right: 2px solid #FFFFFF !important; }
    .tabela-notas-global .-header .rt-th:nth-child(18) {
      border-right: 2px solid #FFFFFF !important; }
    .tabela-notas-global .-header .rt-th:nth-child(24) {
      border-right: 2px solid #FFFFFF !important; }

.tabela-notas-global .rt-tr-group .rt-td {
  font-size: 13px;
  background: #F4F4F4 !important;
  padding: 10px 0; }
  .tabela-notas-global .rt-tr-group .rt-td:nth-child(1) {
    border-right: 2px solid #FFFFFF !important;
    text-decoration: underline; }
  .tabela-notas-global .rt-tr-group .rt-td:nth-child(7) {
    border-right: 2px solid #FFFFFF !important; }
  .tabela-notas-global .rt-tr-group .rt-td:nth-child(12) {
    border-right: 2px solid #FFFFFF !important; }
  .tabela-notas-global .rt-tr-group .rt-td:nth-child(18) {
    border-right: 2px solid #FFFFFF !important; }
  .tabela-notas-global .rt-tr-group .rt-td:nth-child(24) {
    border-right: 2px solid #FFFFFF !important; }

.tabela-notas-global .rt-tfoot .rt-td {
  background: #004C99 !important;
  color: #FFFFFF !important;
  padding: 0px 0px 0px 0px; }
  .tabela-notas-global .rt-tfoot .rt-td p {
    padding: 10px 0px 10px 0px; }
  .tabela-notas-global .rt-tfoot .rt-td table {
    margin: 0;
    padding: 0;
    max-width: 100%;
    display: block; }
    .tabela-notas-global .rt-tfoot .rt-td table tbody {
      max-width: 100%;
      display: block; }
    .tabela-notas-global .rt-tfoot .rt-td table td {
      max-width: 100%;
      display: block; }
    .tabela-notas-global .rt-tfoot .rt-td table tr:first-child {
      display: none; }
    .tabela-notas-global .rt-tfoot .rt-td table tr {
      padding: 10px 0px 10px 0px;
      display: block; }
      .tabela-notas-global .rt-tfoot .rt-td table tr:nth-child(2) {
        background: #033F7B; }
  .tabela-notas-global .rt-tfoot .rt-td:nth-child(7) {
    border-right: 2px solid #FFFFFF !important; }
  .tabela-notas-global .rt-tfoot .rt-td:nth-child(12) {
    border-right: 2px solid #FFFFFF !important; }
  .tabela-notas-global .rt-tfoot .rt-td:nth-child(18) {
    border-right: 2px solid #FFFFFF !important; }
  .tabela-notas-global .rt-tfoot .rt-td:nth-child(24) {
    border-right: 2px solid #FFFFFF !important; }

.right-arrow {
  position: absolute;
  right: 5px; }

.hide {
  display: none !important; }

.total {
  display: inline-flex;
  font-size: 14px; }
  .total li {
    padding: 0 10px;
    font-weight: 400;
    color: #4A4747; }
    .total li:first-child {
      margin-right: 20px; }
    .total li span {
      font-weight: 600 !important;
      color: #033F7B; }

.tooltip-table {
  font-size: 12px !important;
  color: #004C99; }

.alerta {
  color: #DD7C60; }

.legendas li {
  color: #4A4747;
  display: inline;
  padding: 0 10px;
  font-size: 14px; }
  .legendas li:nth-child(1) {
    font-weight: 400; }
  .legendas li:nth-child(n+2) {
    font-weight: 100; }

.legendas .fas {
  margin-right: 5px; }

.legendas .rosa {
  color: #F0D9D2; }

.legendas .amarelho {
  color: #F6EEC3; }

.lista-resumo-correcoes {
  width: 100%;
  text-align: center;
  background: #F4F4F4; }
  .lista-resumo-correcoes p {
    margin-bottom: 0 !important;
    padding: 0 !important; }
  .lista-resumo-correcoes .rt-thead {
    background: #F4F4F4 !important;
    border-bottom: 2px solid #DEDEDE !important; }
  .lista-resumo-correcoes .-headerGroups .rt-th {
    font-size: 14px !important;
    color: #033F7B !important;
    font-weight: 600;
    border-right: 2px solid #FFFFFF !important;
    padding: 10px; }
  .lista-resumo-correcoes .-header {
    color: #033F7B !important;
    font-size: 15px !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
    .lista-resumo-correcoes .-header .rt-th {
      padding: 15px 0;
      border-right: 2px solid #FFFFFF !important;
      font-weight: 600; }
    .lista-resumo-correcoes .-header .rt-th .rt-resizable-header-content {
      color: #033F7B !important;
      font-weight: 400 !important; }
    .lista-resumo-correcoes .-header .rt-th:nth-child(n+3) {
      font-size: 13px; }
      .lista-resumo-correcoes .-header .rt-th:nth-child(n+3) .rt-resizable-header-content {
        background: #FFFFFF !important;
        margin: auto;
        min-width: 30px;
        color: #888585 !important;
        font-weight: 600;
        padding: 2px;
        border-radius: 4px;
        border: 1px solid #888585 !important; }
  .lista-resumo-correcoes .rt-tr-group {
    border-bottom: none !important; }
    .lista-resumo-correcoes .rt-tr-group .F6EEC3:hover {
      background: #efe3a6 !important; }
    .lista-resumo-correcoes .rt-tr-group .F0D9D2:hover {
      background: #f5cabd !important; }
    .lista-resumo-correcoes .rt-tr-group .rt-td {
      font-size: 13px !important;
      padding: 10px 0 !important;
      color: #888585 !important;
      border-right: 2px solid #FFFFFF !important; }
      .lista-resumo-correcoes .rt-tr-group .rt-td:nth-child(1) {
        background: #D8E2EB;
        border-bottom: none !important;
        border-top: none !important; }
      .lista-resumo-correcoes .rt-tr-group .rt-td:hover {
        cursor: pointer; }
  .lista-resumo-correcoes .pagination-bottom {
    background: #FFFFFF;
    border: none !important; }

.acompanhamento-realatorio-correcoes {
  width: 100%;
  text-align: center;
  background: #F4F4F4; }
  .acompanhamento-realatorio-correcoes p {
    margin-bottom: 0 !important;
    padding: 0 !important; }
  .acompanhamento-realatorio-correcoes .rt-thead {
    background: #F4F4F4 !important;
    border-bottom: 2px solid #DEDEDE !important; }
  .acompanhamento-realatorio-correcoes .-headerGroups .rt-th {
    font-size: 14px !important;
    color: #033F7B !important;
    font-weight: 600;
    border-right: 2px solid #FFFFFF !important;
    padding: 10px; }
  .acompanhamento-realatorio-correcoes .-header {
    color: #033F7B !important;
    font-size: 15px !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
    .acompanhamento-realatorio-correcoes .-header .rt-th {
      padding: 15px 0;
      border-right: 2px solid #FFFFFF !important;
      font-weight: 600; }
    .acompanhamento-realatorio-correcoes .-header .rt-th .rt-resizable-header-content {
      color: #033F7B !important; }
    .acompanhamento-realatorio-correcoes .-header .rt-th:nth-child(n+3) {
      font-size: 13px; }
      .acompanhamento-realatorio-correcoes .-header .rt-th:nth-child(n+3) .rt-resizable-header-content {
        background: #FFFFFF !important;
        margin: auto;
        min-width: 30px;
        color: #888585 !important;
        font-weight: 600;
        padding: 2px;
        border-radius: 4px;
        border: 1px solid #888585 !important; }
  .acompanhamento-realatorio-correcoes .rt-tr-group {
    border-bottom: none !important; }
    .acompanhamento-realatorio-correcoes .rt-tr-group .F6EEC3:hover {
      background: #efe3a6 !important; }
    .acompanhamento-realatorio-correcoes .rt-tr-group .F0D9D2:hover {
      background: #f5cabd !important; }
    .acompanhamento-realatorio-correcoes .rt-tr-group .rt-td {
      font-size: 13px !important;
      padding: 10px 0 !important;
      color: #888585 !important;
      border-right: 2px solid #FFFFFF !important; }
      .acompanhamento-realatorio-correcoes .rt-tr-group .rt-td:nth-child(1) {
        background: #D8E2EB;
        border-bottom: none !important;
        border-top: none !important; }
      .acompanhamento-realatorio-correcoes .rt-tr-group .rt-td:hover {
        cursor: pointer; }
  .acompanhamento-realatorio-correcoes .pagination-bottom {
    background: #FFFFFF;
    border: none !important; }

.table-resumo-correcoes .-headerGroups .rt-th {
  font-size: 14px !important;
  color: #033F7B !important;
  font-weight: 600;
  padding: 10px; }
  .table-resumo-correcoes .-headerGroups .rt-th:nth-child(-n + 4) {
    border-right: 2px solid #FFFFFF !important; }

.table-resumo-correcoes .rt-th .rt-resizable-header-content {
  color: #033F7B !important;
  font-weight: 400 !important; }

.table-resumo-correcoes .rt-th:nth-child(n+3) {
  font-size: 13px; }
  .table-resumo-correcoes .rt-th:nth-child(n+3) .rt-resizable-header-content {
    background: #FFFFFF !important;
    margin: auto;
    max-width: 20px;
    color: #888585 !important;
    font-weight: 600;
    padding: 2px;
    border-radius: 4px;
    border: 1px solid #888585 !important;
    min-width: 100% !important; }
  .table-resumo-correcoes .rt-th:nth-child(n+3):nth-child(25) .rt-resizable-header-content {
    min-width: 100% !important; }

.table-resumo-correcoes .rt-thead {
  background: #F4F4F4 !important;
  border-bottom: 2px solid #FFFFFF !important; }

.table-resumo-correcoes .-header {
  color: #033F7B !important;
  font-size: 15px !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }
  .table-resumo-correcoes .-header .rt-th {
    padding: 15px 0; }
    .table-resumo-correcoes .-header .rt-th:nth-child(2) {
      border-right: 2px solid #FFFFFF !important; }

.table-resumo-correcoes .rt-tr-group .-odd .rt-td {
  background: #eee !important; }

.table-resumo-correcoes .rt-tr-group .rt-td {
  font-size: 13px;
  background: #F4F4F4 !important;
  height: 40px;
  line-height: 25px;
  border-right: 0.8px solid #DEDEDE; }
  .table-resumo-correcoes .rt-tr-group .rt-td:nth-child(1) {
    border-right: 2px solid #FFFFFF !important;
    text-decoration: underline;
    background: #D8E2EB !important;
    border-bottom: none !important;
    border-top: none !important; }
  .table-resumo-correcoes .rt-tr-group .rt-td:nth-child(2) {
    border-right: 2px solid #FFFFFF !important; }

.table-resumo-correcoes .rt-tfoot .rt-td {
  padding: 10px 0;
  background: #033F7B !important;
  font-size: 13px; }
  .table-resumo-correcoes .rt-tfoot .rt-td:nth-child(1) {
    border: none !important;
    border-right: 2px solid #FFFFFF !important; }

@media (max-width: 600px) {
  .total, .legendas {
    font-size: 12px;
    display: grid !important; }
    .total li, .legendas li {
      font-size: 12px; } }

.redacao-form {
  display: flex; }
  .redacao-form label {
    display: none; }
  .redacao-form .form-control {
    border-left: 10px solid #ced4da;
    margin-right: 20px; }

.redacao-header {
  background: #f8f8f8;
  padding: 20px;
  display: flex;
  align-items: center; }
  .redacao-header p {
    color: #033F7B;
    font-weight: 300;
    letter-spacing: 1px;
    border-right: 2px solid #004C99;
    margin: 0;
    padding-right: 10px;
    margin-right: 10px; }
    .redacao-header p strong {
      font-weight: bold; }

.redacao-modal .rodal-dialog {
  width: 80% !important;
  align-self: center !important;
  top: unset !important;
  bottom: unset !important;
  height: auto !important;
  margin-top: 10% !important;
  padding: 8px !important; }
  .redacao-modal .rodal-dialog .correcao {
    margin: 0px; }

.box-redacoes-desempenho .panel-default {
  margin-left: 0px;
  margin-right: 0px;
  background: #ffffff;
  border: none; }
  .box-redacoes-desempenho .panel-default .panel-body-avisos {
    background: #f7f7f7;
    border: 2px solid #f2f2f2;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 35px;
    padding-right: 35px; }
    .box-redacoes-desempenho .panel-default .panel-body-avisos h3 {
      font-size: 15px;
      font-weight: 600;
      text-transform: uppercase;
      color: #004C99;
      letter-spacing: 1px;
      margin-top: 0px; }
    .box-redacoes-desempenho .panel-default .panel-body-avisos .info-redacao-desempenho {
      text-align: center;
      background: #fff;
      font-size: 12px;
      padding: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      border-radius: 6px;
      color: #888585;
      border: 1px solid #ccc; }
      .box-redacoes-desempenho .panel-default .panel-body-avisos .info-redacao-desempenho span {
        font-weight: 600; }

.table-responsabilidade .-headerGroups {
  background: #FFFFFF !important;
  color: #033F7B !important; }
  .table-responsabilidade .-headerGroups .rt-tr {
    border-bottom: 2px solid #E8E8E8 !important; }
  .table-responsabilidade .-headerGroups .rt-th {
    font-weight: 600; }
    .table-responsabilidade .-headerGroups .rt-th:nth-child(1) {
      border-right: 3px solid #E8E8E8 !important; }
    .table-responsabilidade .-headerGroups .rt-th:nth-child(2) {
      border-right: 3px solid #E8E8E8 !important; }

.table-responsabilidade .rt-thead {
  background: #FFFFFF;
  border-bottom: 1px solid #E8E8E8 !important; }
  .table-responsabilidade .rt-thead .rt-th {
    border-right: 1px solid #E8E8E8 !important;
    font-size: 12px;
    padding: 15px 0px !important;
    letter-spacing: 0.3px; }
    .table-responsabilidade .rt-thead .rt-th .rt-resizable-header-content {
      color: #033F7B !important;
      font-weight: 600 !important; }
    .table-responsabilidade .rt-thead .rt-th:nth-child(9) {
      border-right: 3px solid #E8E8E8 !important; }
    .table-responsabilidade .rt-thead .rt-th:nth-child(15) {
      border-right: 3px solid #E8E8E8 !important; }

.table-responsabilidade .rt-tbody .rt-tr-group {
  background: #F4F4F4;
  border-bottom: 0px !important; }
  .table-responsabilidade .rt-tbody .rt-tr-group:nth-child(odd) {
    background: #FFFFFF; }

.table-responsabilidade .rt-tbody .rt-tr:hover {
  background: #DEDEDE !important; }

.table-responsabilidade .rt-tbody .rt-td {
  font-size: 13px;
  padding: 10px 0;
  letter-spacing: 0.5px; }
  .table-responsabilidade .rt-tbody .rt-td:nth-child(1n) {
    border-right: 1px solid #E8E8E8 !important; }
  .table-responsabilidade .rt-tbody .rt-td div {
    margin: auto;
    padding: 3px 0; }
  .table-responsabilidade .rt-tbody .rt-td a {
    color: #004C99 !important;
    text-decoration: underline; }
  .table-responsabilidade .rt-tbody .rt-td:nth-child(9) {
    border-right: 3px solid #E8E8E8 !important; }
  .table-responsabilidade .rt-tbody .rt-td:nth-child(15) {
    border-right: 3px solid #E8E8E8 !important; }

.table-responsabilidade .rt-tfoot .rt-td {
  padding: 10px 0; }
  .table-responsabilidade .rt-tfoot .rt-td:nth-child(1n) {
    border-right: 1px solid #E8E8E8 !important; }

.table-responsabilidade .blue-box {
  font-size: 12px;
  background: #004C99;
  color: #FFFFFF;
  border-radius: 4px;
  max-width: 40px;
  margin: auto;
  padding: 3px 0; }

.table-responsabilidade-competencia5 .-headerGroups {
  background: #FFFFFF !important;
  color: #033F7B !important; }
  .table-responsabilidade-competencia5 .-headerGroups .rt-tr {
    border-bottom: 2px solid #E8E8E8 !important; }
  .table-responsabilidade-competencia5 .-headerGroups .rt-th {
    font-weight: 600; }
    .table-responsabilidade-competencia5 .-headerGroups .rt-th:nth-child(1) {
      border-right: 3px solid #E8E8E8 !important; }
    .table-responsabilidade-competencia5 .-headerGroups .rt-th:nth-child(2) {
      border-right: 3px solid #E8E8E8 !important; }

.table-responsabilidade-competencia5 .rt-thead {
  background: #FFFFFF;
  border-bottom: 1px solid #E8E8E8 !important; }
  .table-responsabilidade-competencia5 .rt-thead .rt-th {
    border-right: 1px solid #E8E8E8 !important;
    font-size: 12px;
    padding: 15px 0px !important;
    letter-spacing: 0.3px; }
    .table-responsabilidade-competencia5 .rt-thead .rt-th .rt-resizable-header-content {
      color: #033F7B !important;
      font-weight: 600 !important; }
    .table-responsabilidade-competencia5 .rt-thead .rt-th:nth-child(11) {
      border-right: 3px solid #E8E8E8 !important; }
    .table-responsabilidade-competencia5 .rt-thead .rt-th:nth-child(19) {
      border-right: 3px solid #E8E8E8 !important; }

.table-responsabilidade-competencia5 .rt-tbody .rt-tr-group {
  background: #F4F4F4;
  border-bottom: 0px !important; }
  .table-responsabilidade-competencia5 .rt-tbody .rt-tr-group:nth-child(odd) {
    background: #FFFFFF; }

.table-responsabilidade-competencia5 .rt-tbody .rt-tr:hover {
  background: #DEDEDE !important; }

.table-responsabilidade-competencia5 .rt-tbody .rt-td {
  font-size: 13px;
  padding: 10px 0;
  letter-spacing: 0.5px; }
  .table-responsabilidade-competencia5 .rt-tbody .rt-td:nth-child(1n) {
    border-right: 1px solid #E8E8E8 !important; }
  .table-responsabilidade-competencia5 .rt-tbody .rt-td div {
    margin: auto;
    padding: 3px 0; }
  .table-responsabilidade-competencia5 .rt-tbody .rt-td a {
    color: #004C99 !important;
    text-decoration: underline; }
  .table-responsabilidade-competencia5 .rt-tbody .rt-td:nth-child(11) {
    border-right: 3px solid #E8E8E8 !important; }
  .table-responsabilidade-competencia5 .rt-tbody .rt-td:nth-child(19) {
    border-right: 3px solid #E8E8E8 !important; }

.table-responsabilidade-competencia5 .rt-tfoot .rt-td {
  padding: 10px 0; }
  .table-responsabilidade-competencia5 .rt-tfoot .rt-td:nth-child(1n) {
    border-right: 1px solid #E8E8E8 !important; }

.table-responsabilidade-competencia5 .blue-box {
  font-size: 12px;
  background: #004C99;
  color: #FFFFFF;
  border-radius: 4px;
  max-width: 40px;
  margin: auto;
  padding: 3px 0; }

.btn-outline-secondary {
  border: 1px solid #E8E8E8 !important;
  color: #7D7E7E !important; }
  .btn-outline-secondary:hover {
    background: #E8E8E8 !important;
    color: #FFFFFF !important; }

.redacao-hyperlink {
  color: #023F76;
  text-decoration: underline;
  border: none;
  background: none; }
  .redacao-hyperlink:focus {
    outline: none; }
  .redacao-hyperlink:hover {
    color: #032D53;
    cursor: pointer; }

.responsabilidade-modal .rodal-dialog {
  width: 60% !important;
  align-self: center !important;
  top: unset !important;
  bottom: unset !important;
  height: auto !important;
  margin-top: 10% !important;
  padding: 8px !important; }
  .responsabilidade-modal .rodal-dialog .correcao {
    margin: 0px; }

.table-relatorio .rt-thead {
  background: #FFFFFF;
  border-bottom: 1px solid #E8E8E8 !important; }
  .table-relatorio .rt-thead .rt-th {
    border-right: 1px solid #E8E8E8 !important;
    font-size: 14px;
    padding: 15px 0px !important;
    letter-spacing: 0.3px; }
    .table-relatorio .rt-thead .rt-th .rt-resizable-header-content {
      color: #9FA1A2 !important;
      font-weight: 400 !important; }

.table-relatorio .rt-tbody .rt-tr-group {
  background: #F4F4F4;
  border-bottom: 0px !important; }
  .table-relatorio .rt-tbody .rt-tr-group:nth-child(odd) {
    background: #FFFFFF; }

.table-relatorio .rt-tbody .rt-tr:hover {
  background: #DEDEDE !important; }

.table-relatorio .rt-tbody .rt-td {
  font-size: 13px;
  padding: 10px 0;
  letter-spacing: 0.5px; }
  .table-relatorio .rt-tbody .rt-td:nth-child(1n) {
    border-right: 1px solid #E8E8E8 !important; }
  .table-relatorio .rt-tbody .rt-td div {
    margin: auto;
    padding: 3px 0; }

.table-relatorio .rt-tfoot .rt-td {
  padding: 10px 0; }
  .table-relatorio .rt-tfoot .rt-td:nth-child(1n) {
    border-right: 1px solid #E8E8E8 !important; }

.table-relatorio .blue-box {
  font-size: 12px;
  background: #004C99;
  color: #FFFFFF;
  border-radius: 4px;
  max-width: 40px;
  margin: auto;
  padding: 3px 0; }

.tabela-buscar-projeto {
  padding: 15px 0px !important; }

.tabela-acompanhamento-css {
  width: 100%;
  text-align: center; }
  .tabela-acompanhamento-css p {
    margin-bottom: 0 !important;
    padding: 0 !important; }
  .tabela-acompanhamento-css .rt-thead {
    background: #F4F4F4 !important;
    border-bottom: 2px solid #DEDEDE !important; }
  .tabela-acompanhamento-css .-headerGroups .rt-th {
    height: 100%;
    font-size: 14px !important;
    color: #033F7B !important;
    font-weight: 600;
    border-right: 2px solid #FFFFFF !important;
    padding: 10px; }
  .tabela-acompanhamento-css .-header {
    color: #033F7B !important;
    font-size: 15px !important;
    box-shadow: none !important;
    white-space: normal;
    -webkit-box-shadow: none !important; }
    .tabela-acompanhamento-css .-header .rt-th {
      padding: 15px 0;
      border-right: 2px solid #FFFFFF !important;
      font-weight: 600; }
      .tabela-acompanhamento-css .-header .rt-th:nth-child(1) {
        border-right: 2px solid #FFFFFF !important; }
    .tabela-acompanhamento-css .-header .rt-th .rt-resizable-header-content {
      color: #033F7B !important;
      font-weight: 400 !important; }
    .tabela-acompanhamento-css .-header .rt-th:nth-child(n+3) {
      font-size: 13px; }
      .tabela-acompanhamento-css .-header .rt-th:nth-child(n+3) .rt-resizable-header-content {
        background: #FFFFFF !important;
        margin: auto;
        min-width: 30px;
        color: #888585 !important;
        font-weight: 600;
        padding: 2px;
        border-radius: 4px;
        border: 1px solid #888585 !important; }
  .tabela-acompanhamento-css .rt-tr-group .rt-td:nth-child(1) {
    color: black !important; }
  .tabela-acompanhamento-css .rt-tr-group .rt-td:nth-child(2) {
    color: black !important; }
  .tabela-acompanhamento-css .rt-tr-group .rt-td:nth-child(3) {
    color: black !important; }
  .tabela-acompanhamento-css .rt-tr-group .rt-td:nth-child(4) {
    color: black !important; }
  .tabela-acompanhamento-css .rt-tr-group .rt-td:nth-child(5) {
    color: black !important; }

.text-box {
  border: 1px solid #DEDEDE;
  padding: 5px 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color: #0062a0;
  text-align: center;
  font-size: 15px; }
  .text-box span {
    color: #888585; }

.subtitle {
  font-size: 14px;
  color: #0062a0;
  font-weight: 600; }

.table-resumo {
  border: 1px solid #DEDEDE; }
  .table-resumo thead th {
    font-weight: 600;
    padding-top: 1rem;
    color: #004C99;
    background: #F4F4F4 !important;
    background-color: #F4F4F4 !important;
    border: 1px solid #DEDEDE;
    border-bottom: none !important;
    border-top: none !important;
    font-size: 14px; }
  .table-resumo tbody td {
    text-align: right;
    color: #888585;
    padding: 1rem;
    font-size: 13px;
    border: 1px solid #DEDEDE;
    border-top: none !important; }
