@charset "UTF-8";

@font-face {
  font-family: "canvas-icones";
  src:url("fonts/canvas-icones.eot");
  src:url("fonts/canvas-icones.eot?#iefix") format("embedded-opentype"),
    url("fonts/canvas-icones.woff") format("woff"),
    url("fonts/canvas-icones.ttf") format("truetype"),
    url("fonts/canvas-icones.svg#canvas-icones") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "canvas-icones" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "canvas-icones" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow:before {
  content: "\61";
  font-size:40px;
}
.icon-brush:before {
  content: "\62";
  font-size:30px;
}
.icon-selector:before {
  content: "\64";
  font-size:35px;
}
.icon-trash:before {
  content: "\65";
  font-size:25px;
}
.icon-pencil:before {
  content: "\63";
  font-size:25px;
}
