.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 8;
  overflow: auto;
}

.modal_container {
  background-color: white;
  border-radius: 5px;
  gap: 19px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 20px;
  max-width: 604px;
  overflow: auto;
  z-index: 9;
}

.tittle_modal {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  text-align: left;
  color: #646464;
}

.describe_modal {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #646464;
  margin-top: 19px;
}

.input_text {
  width: 556px;
  max-height: 160px;
  min-height: 160px;
  padding: 8px 0px 8px 8px;
  gap: 0px;
  border-radius: 4px;
  border: 2px solid #C0C0C0;
  margin-top: 24px;
  overflow: auto;
}

.input_text_read {
  width: 556px;
  max-height: 160px;
  min-height: 160px;
  padding: 8px 0px 8px 8px;
  gap: 0px;
  border-radius: 4px;
  border: 2px solid #C0C0C0;
  overflow: auto;
}

.line_break {
  width: 98%;
  height: 2px;
  border-top: 1px solid #C0C0C0;
  margin: 24px 2px;
}

.section_buttons{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.button_secondary {
  height: 43px;
  padding: 8px 16px;
  border-radius: 8px;
  border: 2px solid #0047B6;
  color: #0047B6;
  background-color: transparent;
}

.button_primary {
  height: 43px;
  padding: 8px 16px;
  border-radius: 8px;
  border: 2px solid #0047B6;
  color: white;
  background-color: #0047B6;
  margin-left: 20px;
}
